import { useEffect } from 'react';
import {
  Grid,
  useMediaQuery,
  CircularProgress,
  Backdrop,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ThemeStyles from 'ThemeStyles';
import { useDrawerContext } from 'context/DrawerContext';
import { useContentContext } from 'context/ContentContext';
import PropTypes from 'prop-types';

const styles = () => ({
  appBar: {
    backgroundColor: ThemeStyles.customColor.background.primary,
    minHeight: '100vh',
    position: 'relative',

    '@media print': {
      padding: 0,
      backgroundColor: 'white',
    },
  },
  appBarShift: {
    width: `calc(100% - ${ThemeStyles.drawerWidth}px)`,
    marginLeft: ThemeStyles.drawerWidth,
  },
  backdrop: {
    zIndex: 1199,
  },
  backdropDrawer: {
    zIndex: 1310,
  },
});

const PageContainer = (props) => {
  const { classes } = props;
  const { setDrawerState, getDrawerState } = useDrawerContext();
  const { getLoading } = useContentContext();
  const mobileScreen = useMediaQuery('(max-width:600px)');
  const mediumScreen = useMediaQuery('(max-width:1280px)');

  const Loading = () => (
    <Backdrop
      className={
        getLoading().topPosition ? classes.backdropDrawer : classes.backdrop
      }
      open>
      <CircularProgress
        style={{
          position: 'absolute',
          marginLeft:
            getLoading().topPosition || mediumScreen
              ? 0
              : ThemeStyles.drawerWidth,
          color: '#fff',
        }}
      />
      {getLoading().label ? (
        <Typography
          variant="h6"
          style={{
            marginTop: 100,
            marginLeft: getLoading().topPosition ? 0 : ThemeStyles.drawerWidth,
            fontFamily: 'Poppins-Regular',
            color: '#fff',
          }}>
          {getLoading().label}
        </Typography>
      ) : null}
    </Backdrop>
  );

  useEffect(() => {
    if (
      !getDrawerState('showDrawer') ||
      getDrawerState().showDrawer !== !props.noDrawer
    ) {
      setDrawerState({ showDrawer: !props.noDrawer });
    }
    // eslint-disable-next-line
  }, [props.noDrawer]);

  return (
    <Grid
      className={`${classes.appBar} ${
        mediumScreen || props.noDrawer ? '' : classes.appBarShift
      }`}
      style={{
        padding: mobileScreen ? '80px 8px 0' : '88px 24px 0',
      }}>
      {getLoading().loading && <Loading />}
      {props.children}
    </Grid>
  );
};

PageContainer.propTypes = {
  classes: PropTypes.object,
  noDrawer: PropTypes.bool,
  children: PropTypes.element.isRequired,
};

export default withStyles(styles)(PageContainer);
