// sengaja dibuat warna sendiri karena diluar app,
// hanya untuk info developer

import { getLSDevlog } from 'localstorage/getter';
import { _ISNOTPRODUCTION } from './functions';

export const logColor = {
  success: 'background: #81c784; color: #1a237e',
  error: 'background: #ff6659; color: #fff',
  info: 'background: #81d4fa; color: #1a237e',
};

export const logInfo = (value) => {
  if (_ISNOTPRODUCTION()) {
    console.log(`%c ${value}`, logColor.info);
  }
};

export const logPayloadResponse = (func, payload, response, status) => {
  const devlog = getLSDevlog();
  const disableLogging = devlog?.disable_bo_log || false;
  if (_ISNOTPRODUCTION() && !disableLogging) {
    let localStatus = 'success';
    if (
      response &&
      (response.status === false ||
        response.error === true ||
        status === 'error')
    ) {
      localStatus = 'error';
    }
    console.log(
      `%c ▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼START ${func}▼▼▼▼▼▼▼▼▼▼▼▼▼▼`,
      logColor[localStatus],
    );

    console.log(`STATUS : ${localStatus.toUpperCase()}`);
    console.log(`FUNCTION : ${func}`);
    console.log('PAYLOAD :');
    console.log(`${JSON.stringify(payload)}`, null, 4);
    if (status === 'success') {
      console.log('RESPONSE :');
      console.log(response);
    }

    console.log(
      `%c ▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲▲END ${func}▲▲▲▲▲▲▲▲▲▲▲▲▲▲`,
      logColor[localStatus],
    );
  }
};
