import { SSName } from '.';

export const getSessionStorage = (key, raw) => {
  const item = sessionStorage.getItem(key);
  if (raw) return item;
  if (item === 'undefined') return null;
  return item ? JSON.parse(item) : item;
};

export const getAuthSession = () => {
  return getSessionStorage(SSName.AUTH);
};

export const getCredentialSession = () => {
  return getSessionStorage(SSName.CRED);
};

export const getSessionOutlet = () => {
  return getSessionStorage(SSName.SELECTEDOUTLET);
};

export const getSessionBusiness = () => {
  return getSessionStorage(SSName.SELECTEDBUSINESS);
};

export const getSessionMenu = () => {
  return getSessionStorage(SSName.SELECTEDMENU, true);
};
