/* eslint-disable no-param-reassign */
import {
  useState,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';

import { Select, MenuItem, FormControl, Box } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useDrawerContext } from 'context/DrawerContext';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100,
    width: '100%',
  },
  formControlThin: {
    margin: `0px ${theme.spacing(1)}px`,
    minWidth: 100,
    width: '100%',
  },
  whiteColor: {
    fill: theme.customColor.text.secondary,
  },
  blueColor: {
    fill: theme.customColor.text.placeholder,
  },
  disabledColor: {
    fill: theme.palette.disabled.main,
  },
}));

/*
props list:
- IconName
- defaultValue
- onChange
- placeholder
- blueColor
- thin
*/

const constructData = (data) => {
  if (data.length > 0) {
    data.forEach((item) => {
      item.value = item.id ? item.id.toString() : item.value.toString();
      item.name = item.label || item.name;
    });
  }
  return data;
};

const handleOnChange = (event, props, setState) => {
  const newValue =
    typeof props.value === 'string'
      ? event.target.value.toString()
      : event.target.value;

  if (props.onChange) {
    props.onChange(newValue);
  } else {
    setState({ value: newValue });
  }
};

const getSelectValue = (props, state) => {
  if (props.value && state.listData.length > 0) {
    return props.value;
  }
  return state.value;
};

const getUpdateListDataLocal = (props, setState) => {
  if (props.ListItem && props.ListItem.length > 0) {
    setState({
      listData: constructData(props.ListItem),
    });
  }
};

const getUpdateValueLocal = (props, state, setState) => {
  if ((!state.value || props.value === '') && state.listData.length > 0) {
    setState({ value: state.listData[0].value });
  }
};

const getBaseColor = (props, theme) => {
  if (props.disabled) return theme.palette.disabled.main;
  if (props.textColor) return props.textColor;

  return props.blueColor
    ? theme.customColor.text.primary
    : theme.customColor.text.secondary;
};

const getInputPropsColor = (props, classes) => {
  if (props.disabled) return classes.disabledColor;
  if (props.blueColor) return classes.blueColor;

  return classes.whiteColor;
};

const setDefaultBusinessOutlet = (
  props,
  getSelectedBusiness,
  getSelectedOutlet,
  setState,
) => {
  if (!props.defaultValue) {
    if (props.IconName === 'business_center') {
      setState({ value: getSelectedBusiness() });
    } else if (props.IconName === 'storefront') {
      setState({ value: getSelectedOutlet() });
    }
  }
};

const ReSelectItem = forwardRef((props, ref) => {
  const { getSelectedBusiness, getSelectedOutlet } = useDrawerContext();
  const theme = useTheme();
  const classes = useStyles();
  const [localState, setLocalState] = useState({
    value: props.defaultValue || '',
    listData: [],
  });
  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const updateLocalState = useCallback((newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      return getLocalState().value;
    },
    setValue(value) {
      updateLocalState({ value });
    },
    setData(listData) {
      if (listData) {
        updateLocalState({
          listData: constructData(listData),
        });
      }
    },
  }));

  useEffect(() => {
    setDefaultBusinessOutlet(
      props,
      getSelectedBusiness,
      getSelectedOutlet,
      updateLocalState,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUpdateListDataLocal(props, updateLocalState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ListItem]);

  useEffect(() => {
    getUpdateValueLocal(props, getLocalState(), updateLocalState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocalState().listData]);

  useEffect(() => {
    if (props.onFinish) {
      props.onFinish(getLocalState().value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localState.value]);

  return (
    <Box
      className={classes.container}
      style={{
        ...props.style,
      }}>
      <Box>
        <Icon
          style={{
            fontSize: 24,
            verticalAlign: 'middle',
            color: props.iconColor || 'inherit',
            marginTop: -4,
          }}>
          {props.IconName}
        </Icon>
      </Box>
      <Box style={{ width: `calc(100% - 30px)` }}>
        <FormControl
          className={
            props.thin ? classes.formControlThin : classes.formControl
          }>
          <Select
            id={`formSelect-${props['id'] || 'default'}`}
            disableUnderline
            displayEmpty
            disabled={!!props.disabled}
            MenuProps={{
              style: { zIndex: 1310 },
            }}
            style={{ color: getBaseColor(props, theme) }}
            color={props.selectedColor}
            inputProps={{
              classes: {
                icon: getInputPropsColor(props, classes),
              },
            }}
            value={getSelectValue(props, getLocalState())}
            onChange={(event) => {
              handleOnChange(event, props, updateLocalState);
            }}>
            {props.placeholder ? (
              <MenuItem
                id={`formSelectMenu-${props['id'] || 'default'}`}
                data-testid={`filter-item-${props['data-testid'] || 'default'}`}
                disabled>
                {props.placeholder}
              </MenuItem>
            ) : null}
            {getLocalState().listData.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem
                id={`formSelectMenu-${props['id'] || 'default'}-${index}`}
                data-testid={`filter-item-${props['data-testid'] || 'default'}`}
                key={index}
                value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
});

ReSelectItem.displayName = 'ReSelectItem';

ReSelectItem.propTypes = {
  id: PropTypes.string,
  'data-testid': PropTypes.string,
  defaultValue: PropTypes.string,
  ListItem: PropTypes.array,
  onFinish: PropTypes.func,
  style: PropTypes.object,
  iconColor: PropTypes.string,
  IconName: PropTypes.string,
  placeholder: PropTypes.string,
  selectedColor: PropTypes.string,
  thin: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default ReSelectItem;
