import { Suspense, lazy } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import ReSimpleCircularProgress from 'components/ReSimpleCircularProgress';
import PermanentDrawer from 'components/PermanentDrawer';
import AuthProvider from 'context/AuthContext';
import { DrawerMenuData } from 'assets/file/DrawerMenuList';
import { _ISNOTPRODUCTION } from 'utils/functions';
import { PERMIT_HELPER } from 'assets/file/AccessControl';
import PropTypes from 'prop-types';

const GeneralPage = lazy(() => import('./pages/general-site/GeneralPage'));
const PageNotFound = lazy(() => import('./pages/general-site/PageNotFound'));

const DepartmentPage = lazy(() =>
  import('./pages/back-office/admin-panel/products/department/DepartmentPage'),
);

const GroupPage = lazy(() =>
  import('./pages/back-office/admin-panel/products/group/GroupPage'),
);

const ProductListPage = lazy(() =>
  import('./pages/back-office/admin-panel/products/product/ProductListPage'),
);
const AddProductPage = lazy(() =>
  import('./pages/back-office/admin-panel/products/product/AddProductPage'),
);
const MenuPage = lazy(() =>
  import('./pages/back-office/admin-panel/products/menuPage/MenuPage'),
);

const AddMenuPage = lazy(() =>
  import('./pages/back-office/admin-panel/products/menuPage/AddMenuPage'),
);

const MenuVariantPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/products/menuVariant/MenuVariantPage'
  ),
);
const AddMenuVariantPage = lazy(() =>
  import('./pages/back-office/admin-panel/products/menuVariant/AddVariantPage'),
);
const PackagePage = lazy(() =>
  import('./pages/back-office/admin-panel/products/package/PackagePage'),
);
const AddPackagePage = lazy(() =>
  import('./pages/back-office/admin-panel/products/package/AddPackagePage'),
);
const ModifierPage = lazy(() =>
  import('./pages/back-office/admin-panel/products/modifier/ModifierPage'),
);
const HierarchyPage = lazy(() =>
  import('./pages/back-office/admin-panel/products/HierarchyPage'),
);
const OutletPage = lazy(() =>
  import('./pages/back-office/admin-panel/outlet/outlet-list/OutletPage'),
);
const DevicePage = lazy(() =>
  import('./pages/back-office/admin-panel/outlet/device/DevicePage'),
);
const DeviceColor = lazy(() =>
  import('./pages/back-office/admin-panel/outlet/device/DeviceColor'),
);
const ConfigurationPage = lazy(() =>
  import('./pages/back-office/admin-panel/outlet/configure/ConfigurationPage'),
);

const UserProfilePage = lazy(() =>
  import('./pages/back-office/admin-panel/user-profile/UserProfilePage'),
);

const EmployeeListPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/employee/employeeList/EmployeeListPage'
  ),
);

const DetailEmployeePage = lazy(() =>
  import(
    './pages/back-office/admin-panel/employee/employeeList/DetailEmployeePage'
  ),
);

const AddEmployeePage = lazy(() =>
  import(
    './pages/back-office/admin-panel/employee/employeeList/AddEmployeePage'
  ),
);

const RoleListPage = lazy(() =>
  import('./pages/back-office/admin-panel/employee/role/RoleListPage'),
);

const AddRolePage = lazy(() =>
  import('./pages/back-office/admin-panel/employee/role/AddRolePage'),
);

const DetailRolePage = lazy(() =>
  import('./pages/back-office/admin-panel/employee/role/DetailRolePage'),
);

const StockSummaryPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/inventory/stock-summary/StockSummaryPage'
  ),
);

const StockAdjustmentPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/inventory/stock-adjustment/StockAdjustmentPage'
  ),
);

const StockAuditPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/inventory/stock-audit/StockAuditPage'
  ),
);

const RefundMemoPage = lazy(() =>
  import('./pages/back-office/admin-panel/utility/refund-memo/RefundMemoPage'),
);

const VoidMemoPage = lazy(() =>
  import('./pages/back-office/admin-panel/utility/void-memo/VoidMemoPage'),
);

const TypeSalesPage = lazy(() =>
  import('./pages/back-office/admin-panel/utility/type-sales/TypeSalesPage'),
);

const PaymentMediaPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/utility/payment-media/PaymentMediaPage'
  ),
);

const BillDesignPage = lazy(() =>
  import('./pages/back-office/admin-panel/utility/bill-design/BillDesignPage'),
);

const TaxesPage = lazy(() =>
  import('./pages/back-office/admin-panel/utility/taxes/TaxesPage'),
);

const InformationPage = lazy(() =>
  import('./pages/back-office/admin-panel/utility/Information'),
);

const DeviceLicense = lazy(() =>
  import(
    './pages/back-office/admin-panel/setting/device-license/DeviceLicense'
  ),
);

const SubscriptionPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/setting/subscription/SubscriptionPage'
  ),
);

const DetailPaymentPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/setting/subscription/TemporaryPayment'
  ),
);

// TODO SEMENTARA DIGANTI DENGAN HALAMAN TEMPORARY
// const DetailPaymentPage = lazy(() =>
//   import(`${admin_panel}/setting/subscription/DetailPaymentPage`),
// );

const BillingHistoryPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/setting/billing-history/BillingHistoryPage'
  ),
);

const MemberPage = lazy(() =>
  import('./pages/back-office/admin-panel/member-list/MemberPage'),
);

const AddMemberPage = lazy(() =>
  import('./pages/back-office/admin-panel/member-list/AddMemberPage'),
);

const TableLayoutPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/manage-table/table-layout/TableLayoutPage'
  ),
);

const InvoicePage = lazy(() =>
  import('./pages/back-office/admin-panel/invoice/InvoicePage'),
);

const DetailInvoicePage = lazy(() =>
  import('./pages/back-office/admin-panel/invoice/DetailInvoicePage'),
);

const PrintPreviewInvoice = lazy(() =>
  import('./pages/back-office/admin-panel/invoice/PrintPreviewInvoice'),
);

const PrintPreviewBillingHistoryPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/setting/billing-history/PrintPreviewBillingHistoryPage'
  ),
);

const PrintPreviewStockAuditPage = lazy(() =>
  import(
    './pages/back-office/admin-panel/inventory/stock-audit/PrintPreviewStockAuditPage'
  ),
);

const SalesDashboard = lazy(() =>
  import('./pages/back-office/admin-panel/report/SalesDashboard'),
);

const EMoneyPage = lazy(() =>
  import('./pages/back-office/admin-panel/emoney/EMoneyPage'),
);

const ActivateEmoney = lazy(() =>
  import('./pages/back-office/admin-panel/emoney/ActivateEmoney'),
);

const DiscountPage = lazy(() =>
  import('./pages/back-office/admin-panel/utility/discount/DiscountPage'),
);

const CommissionPage = lazy(() =>
  import('./pages/back-office/admin-panel/utility/commission/CommissionPage'),
);

const PromoPage = lazy(() =>
  import('./pages/back-office/admin-panel/promo/PromoPage'),
);

const AddPromoPage = lazy(() =>
  import('./pages/back-office/admin-panel/promo/AddPromoPage'),
);

const PostMessageDev = lazy(() =>
  import('./pages/devpages/PostMessageSendListen'),
);
const DeployInfo = lazy(() => import('./pages/devpages/DeployInfo'));

const DevPath = [
  {
    path: '/dev/postmessage',
    element: <PostMessageDev />,
    auth: true,
    options: {
      title: null,
      menuKey: 'postmessage',
      subMenu: 'postmessage',
    },
    exact: true,
  },
  {
    path: '/dev/info',
    element: <DeployInfo />,
    auth: false,
    options: {
      title: null,
      menuKey: 'deployinfo',
      subMenu: 'deployinfo',
    },
    exact: true,
  },
];

const RoutePaths = [
  { path: '/admin-panel', element: <GeneralPage /> },

  // AUTH ONLY
  // { path: '/dashboard', element: <LandingPage />, auth: true, exact: true },
  {
    path: '/product/department',
    element: <DepartmentPage />,
    auth: true,
    menu: 'product',
    subMenu: 'department',
    exact: true,
  },
  {
    path: '/product/group',
    element: <GroupPage />,
    auth: true,
    menu: 'product',
    subMenu: 'group',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/product/product-list',
    element: <ProductListPage />,
    auth: true,
    menu: 'product',
    subMenu: 'productList',
    exact: true,
  },
  {
    path: '/product/product-list/add',
    element: <AddProductPage />,
    auth: true,
    menu: 'product',
    subMenu: 'productList',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/product/product-list/edit',
    element: <AddProductPage />,
    auth: true,
    menu: 'product',
    subMenu: 'productList',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/product/menu',
    element: <MenuPage />,
    auth: true,
    menu: 'product',
    subMenu: 'menuPage',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/product/menu/add',
    element: <AddMenuPage />,
    auth: true,
    menu: 'product',
    subMenu: 'menuPage',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/product/menu/edit',
    element: <AddMenuPage />,
    auth: true,
    menu: 'product',
    subMenu: 'menuPage',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/product/menu-variant',
    element: <MenuVariantPage />,
    auth: true,
    menu: 'product',
    subMenu: 'menuVariant',
    exact: true,
  },
  {
    path: '/product/menu-variant/add',
    element: <AddMenuVariantPage />,
    auth: true,
    menu: 'product',
    subMenu: 'menuVariant',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/product/menu-variant/edit',
    element: <AddMenuVariantPage />,
    auth: true,
    menu: 'product',
    subMenu: 'menuVariant',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/product/modifier',
    element: <ModifierPage />,
    auth: true,
    menu: 'product',
    subMenu: 'modifier',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/product/package',
    element: <PackagePage />,
    auth: true,
    menu: 'product',
    subMenu: 'package',
    exact: true,
  },
  {
    path: '/product/package/add',
    element: <AddPackagePage />,
    auth: true,
    menu: 'product',
    subMenu: 'package',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/product/package/edit',
    element: <AddPackagePage />,
    auth: true,
    menu: 'product',
    subMenu: 'package',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/product/hierarchy-product',
    element: <HierarchyPage />,
    auth: true,
    options: {
      title: 'information',
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/manage-outlet/outlet-list',
    element: <OutletPage />,
    auth: true,
    menu: 'manageOutlet',
    subMenu: 'outletList',
    options: {
      outletFilter: false,
    },
    exact: true,
  },
  {
    path: '/manage-outlet/device',
    element: <DevicePage />,
    auth: true,
    menu: 'manageOutlet',
    subMenu: 'device',

    exact: true,
  },
  {
    path: '/manage-outlet/device/information',
    element: <DeviceColor />,
    auth: true,
    menu: 'manageOutlet',
    subMenu: 'device',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/manage-outlet/configuration',
    element: <ConfigurationPage />,
    auth: true,
    menu: 'manageOutlet',
    subMenu: 'configuration',
    options: {
      allOutletFilter: false,
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/profile',
    element: <UserProfilePage />,
    auth: true,
    menu: 'profile',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/employee/employee-list',
    element: <EmployeeListPage />,
    auth: true,
    menu: 'employee',
    subMenu: 'employeeList',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/employee/employee-list/add',
    element: <AddEmployeePage />,
    auth: true,
    menu: 'employee',
    subMenu: 'employeeList',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/employee/employee-list/edit',
    element: <AddEmployeePage />,
    auth: true,
    menu: 'employee',
    subMenu: 'employeeList',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/employee/employee-list/detail',
    element: <DetailEmployeePage />,
    auth: true,
    menu: 'employee',
    subMenu: 'employeeList',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/employee/role',
    element: <RoleListPage />,
    auth: true,
    menu: 'employee',
    subMenu: 'role',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/employee/role/add',
    element: <AddRolePage />,
    auth: true,
    menu: 'employee',
    subMenu: 'role',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/employee/role/edit',
    element: <AddRolePage />,
    auth: true,
    menu: 'employee',
    subMenu: 'role',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/employee/role/detail',
    element: <DetailRolePage />,
    auth: true,
    menu: 'employee',
    subMenu: 'role',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/inventory/stock-summary',
    element: <StockSummaryPage />,
    auth: true,
    menu: 'inventory',
    subMenu: 'stockSummary',
    exact: true,
  },
  {
    path: '/inventory/stock-adjustment',
    element: <StockAdjustmentPage />,
    auth: true,
    menu: 'inventory',
    subMenu: 'stockAdjustment',
    options: {
      filterButton: false,
      allOutletFilter: false,
    },
    exact: true,
  },
  {
    path: '/inventory/stock-audit',
    element: <StockAuditPage />,
    auth: true,
    menu: 'inventory',
    subMenu: 'stockAudit',
    options: {
      filterButton: false,
      allOutletFilter: false,
    },
    exact: true,
  },
  {
    path: '/print-preview/stock-audit',
    element: <PrintPreviewStockAuditPage />,
    auth: true,
    options: {
      noDrawer: true,
    },
    exact: true,
  },
  {
    path: '/utility/refund-memo',
    element: <RefundMemoPage />,
    auth: true,
    menu: 'utility',
    subMenu: 'refundMemo',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/utility/void-memo',
    element: <VoidMemoPage />,
    auth: true,
    menu: 'utility',
    subMenu: 'voidMemo',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/utility/type-sales',
    element: <TypeSalesPage />,
    auth: true,
    menu: 'utility',
    subMenu: 'typeSales',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/utility/payment-media',
    element: <PaymentMediaPage />,
    auth: true,
    menu: 'utility',
    subMenu: 'paymentMedia',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/e-money',
    element: <EMoneyPage />,
    auth: true,
    menu: 'emoney',
    options: {
      allOutletFilter: false,
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/e-money/activate',
    element: <ActivateEmoney />,
    auth: true,
    menu: 'emoney',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/utility/discount',
    element: <DiscountPage />,
    auth: true,
    menu: 'utility',
    subMenu: 'discount',

    exact: true,
  },
  {
    path: '/utility/commission',
    element: <CommissionPage />,
    auth: true,
    menu: 'utility',
    subMenu: 'commission',
    options: {
      filterButton: false,
      allOutletFilter: false,
    },
    exact: true,
  },
  {
    path: '/promo',
    element: <PromoPage />,
    auth: true,
    menu: 'promo',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/promo/add',
    element: <AddPromoPage />,
    auth: true,
    menu: 'promo',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/promo/edit',
    element: <AddPromoPage />,
    auth: true,
    menu: 'promo',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/utility/taxes',
    element: <TaxesPage />,
    auth: true,
    menu: 'utility',
    subMenu: 'taxes',
    options: {
      allOutletFilter: false,
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/utility/bill-design',
    element: <BillDesignPage />,
    auth: true,
    menu: 'utility',
    subMenu: 'billDesign',
    options: {
      allOutletFilter: false,
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/utility/information',
    element: <InformationPage />,
    auth: true,
    options: {
      title: 'information',
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/setting/device-license',
    element: <DeviceLicense />,
    auth: true,
    menu: 'setting',
    subMenu: 'deviceLicense',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/setting/billing-history',
    element: <BillingHistoryPage />,
    auth: true,
    menu: 'setting',
    subMenu: 'billingHistory',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/setting/subscription',
    element: <SubscriptionPage />,
    auth: true,
    menu: 'setting',
    subMenu: 'subscription',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/setting/subscription/detail-payment',
    element: <DetailPaymentPage />,
    auth: true,
    menu: 'setting',
    subMenu: 'subscription',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/print-preview/billing-history',
    element: <PrintPreviewBillingHistoryPage />,
    auth: true,
    options: {
      noDrawer: true,
    },
    exact: true,
  },
  {
    path: '/member-list',
    element: <MemberPage />,
    auth: true,
    menu: 'member',
    options: {
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/member-list/add',
    element: <AddMemberPage />,
    auth: true,
    menu: 'member',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/member-list/edit',
    element: <AddMemberPage />,
    auth: true,
    menu: 'member',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/invoice',
    element: <InvoicePage />,
    auth: true,
    menu: 'invoice',
    exact: true,
  },
  {
    path: '/invoice/detail',
    element: <DetailInvoicePage />,
    auth: true,
    menu: 'invoice',
    options: {
      useFilter: false,
    },
    exact: true,
  },
  {
    path: '/print-preview/invoice',
    element: <PrintPreviewInvoice />,
    auth: true,
    options: {
      noDrawer: true,
    },
    exact: true,
  },
  {
    path: '/manage-table/table-layout',
    element: <TableLayoutPage />,
    auth: true,
    menu: 'table',
    subMenu: 'tableLayout',
    options: {
      allOutletFilter: false,
      filterButton: false,
    },
    exact: true,
  },
  {
    path: '/report/sales',
    element: <SalesDashboard />,
    auth: true,
    menu: 'report',
    subMenu: 'sales',
    options: {
      bisnisOutletFilter: false,
      filterButton: false,
    },
    exact: true,
  },

  // PAGE NOT FOUND HARUS DI BAWAH SENDIRI
  {
    path: '*',
    element: <PageNotFound />,
  },
];

const Element = ({ route }) => {
  const pageConstructor = route.menu
    ? {
        title: route.subMenu
          ? DrawerMenuData[route.menu].subMenu[route.subMenu].key
          : DrawerMenuData[route.menu].key,
        menuKey: DrawerMenuData[route.menu].key,
        subMenu: route.subMenu
          ? DrawerMenuData[route.menu].subMenu[route.subMenu].key
          : '',
        ...route.options,
        permitAccess: PERMIT_HELPER(
          DrawerMenuData[route.menu],
          DrawerMenuData[route.menu].subMenu[route.subMenu],
        ),
        disabled: DrawerMenuData[route.menu].disabled,
      }
    : { permitAccess: true, ...route.options };

  return route.auth ? (
    <AuthProvider {...pageConstructor}>{route.element}</AuthProvider>
  ) : (
    route.element
  );
};

Element.propTypes = {
  route: PropTypes.object,
};

const Page = ({ route }) => {
  return <Element route={route} />;
};

Page.propTypes = {
  route: PropTypes.object,
};

const Routes = () => {
  return (
    <>
      <Suspense fallback={<ReSimpleCircularProgress />}>
        <PermanentDrawer />
        <Suspense fallback={<ReSimpleCircularProgress />}>
          <ReactRoutes>
            {RoutePaths.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<Page route={route} />}
              />
            ))}

            {_ISNOTPRODUCTION() &&
              DevPath.map((devpath) => (
                <Route
                  key={devpath.path}
                  path={devpath.path}
                  element={
                    devpath.auth ? (
                      <AuthProvider {...devpath.options}>
                        {devpath.element}
                      </AuthProvider>
                    ) : (
                      devpath.element
                    )
                  }
                />
              ))}
          </ReactRoutes>
        </Suspense>
      </Suspense>
    </>
  );
};

export default Routes;
