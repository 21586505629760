import { getLSI18N } from 'localstorage/getter';
import { clearLocalStorage, setLSI18N } from 'localstorage/setter';
import { clearSessionStorage } from 'sessionstorage/setter';
import * as Mongo from 'mongoservices/setup';
import { sendMessageBOLogout } from './functions';

const logoutBO = async () => {
  const lang = getLSI18N();
  clearSessionStorage();
  clearLocalStorage();
  setLSI18N(lang);
  await Mongo.LogoutRealm();
  sendMessageBOLogout();
  window.location.replace('/admin-panel');
};

const knownCases = (error) => {
  const invalidSession = error.errorCode === 'InvalidSession';
  const rejectedByMongo =
    error?.message?.url ===
      'https://ap-southeast-1.aws.stitch.mongodb.com/api/client/v2.0/auth/session' &&
    error?.message?.statusCode === 401;

  return invalidSession || rejectedByMongo;
};

export default (error) => {
  let result = false;
  if (knownCases(error)) {
    result = true;
    logoutBO();
  }
  return result;
};
