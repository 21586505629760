import { forwardRef, useState, useCallback, useImperativeHandle } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Box,
  Grid,
} from '@material-ui/core';
import ReOvalButton from 'components/ReOvalButton';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      width: 480,
      height: ({ dialogHeight }) => dialogHeight || 380,
      padding: `0 ${theme.spacing(3)}px ${theme.spacing(1)}px`,
    },
  },
  titleContainer: {
    padding: 0,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  title: {
    fontFamily: 'Poppins-SemiBold',
    color: theme.customColor.text.primary,
    textAlign: 'center',
  },
  message: {
    fontFamily: 'Poppins-Regular',
    color: theme.customColor.text.primary,
    textAlign: 'justify',
  },
}));

const initialState = {
  visible: false,
  data: null,
};

const getBackground = (props, getDisabled, theme) => {
  return props.btnColor
    ? props.btnColor
    : !getDisabled() && theme.palette.secondary.main;
};

const getLabel = (props, t) => {
  return props.labelConfirm ? props.labelConfirm : t('confirm').toUpperCase();
};

const getVariant = (props) => {
  return props.confirmButton ? 'outlined' : props.variant || 'contained';
};

const getTitle = (props) => {
  return !props.title ? '' : props.title || 'WARNING';
};

const checkLabel = (props, t) => {
  if (props.labelSend) return props.labelSend;
  if (props.labelCancel) return props.labelCancel;
  return t('close').toUpperCase();
};

const getSize = (props, size) => {
  return props.confirmButton ? 4 : size;
};

const onClickClose = (props) => {
  if (props.onCancel) props.onCancel();
  if (props.onSend) props.onSend();
};

const getCustomMessage = (props, classes) => {
  let message = (
    <Typography variant="body2" className={classes.message}>
      {props.message}
    </Typography>
  );

  if (props.customMessage) {
    message = props.customMessage;
  }

  return message;
};

const checkBackground = (props, theme) => {
  if (props.variant && props.variant === 'outlined') {
    return theme.palette.disabled.text;
  }
  return theme.palette.error.main;
};

const onClickConfirm = (props) => {
  if (props.onConfirm) props.onConfirm();
};

const getSingleOrCancelBackground = (props, theme) => {
  if (props.confirmButton) {
    return 'inherit';
  }
  return props.baseColor ? props.baseColor : checkBackground(props, theme);
};

const getID = (props) => {
  return props['id'] || 'default';
};

const ReSimpleDialog = forwardRef((props, ref) => {
  const theme = useTheme();
  const classes = useStyles({ dialogHeight: props.height || null });
  const { t } = useTranslation();

  const [localState, setLocalState] = useState(initialState);

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const updateLocalState = useCallback((newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  }, []);

  const handleClose = () => {
    updateLocalState(initialState);
  };

  const getDisabled = () => {
    return typeof props.disableConfirm === 'boolean'
      ? props.disableConfirm
      : getLocalState('disabled');
  };

  useImperativeHandle(ref, () => ({
    getValue() {
      return getLocalState('visible');
    },
    setValue(value, data) {
      return updateLocalState({ visible: value, data });
    },
    setIsSubmitable(value) {
      updateLocalState({ disabled: !value });
    },
  }));

  return (
    <>
      {getLocalState('visible') && (
        <Dialog
          id={`dialog-${getID(props)}`}
          style={{ zIndex: 1310 }}
          className={classes.root}
          open
          onClose={() => {
            handleClose();
            if (props.onCancel) {
              props.onCancel();
            }
          }}
          TransitionProps={{
            onEnter: props.onOpen || null,
          }}>
          <Box style={{ position: 'absolute', right: 0 }}>
            <IconButton
              id={`button-close-${getID(props)}Dialog`}
              onClick={() => {
                handleClose();
                if (props.onCancel) {
                  props.onCancel();
                }
              }}
              style={{
                color: theme.customColor.text.primary,
              }}>
              <Close id={`buttonIcon-close-${getID(props)}Dialog`} />
            </IconButton>
          </Box>
          <DialogTitle disableTypography className={classes.titleContainer}>
            <Typography
              variant="h6"
              className={classes.title}
              style={{ ...props.titleStyle }}>
              {getTitle(props)}
            </Typography>
          </DialogTitle>
          <DialogContent
            id={`dialogContent-${getID(props)}`}
            style={{ padding: 0, overflowX: 'hidden' }}>
            {getCustomMessage(props, classes)}
          </DialogContent>
          <DialogActions>
            {props.customButtonAction ? (
              props.customButtonAction
            ) : (
              <Grid container align="center">
                <Grid item xs={getSize(props, 12)}>
                  <ReOvalButton
                    id={`cancel-${getID(props)}Dialog`}
                    style={{
                      backgroundColor: getSingleOrCancelBackground(
                        props,
                        theme,
                      ),
                      width: props.confirmButton ? '100%' : 'fit-content',
                    }}
                    onClick={() => {
                      handleClose();
                      onClickClose(props);
                    }}
                    variantText="body1"
                    variant={getVariant(props)}
                    label={checkLabel(props, t)}
                  />
                </Grid>
                <Grid item xs={getSize(props, 0)} />
                <Grid item xs={getSize(props, 0)}>
                  {props.confirmButton && (
                    <ReOvalButton
                      id={`confirm-${getID(props)}Dialog`}
                      style={{
                        backgroundColor: getBackground(
                          props,
                          getDisabled,
                          theme,
                        ),
                      }}
                      variantText="body1"
                      label={getLabel(props, t)}
                      onClick={() => {
                        handleClose();
                        onClickConfirm(props);
                      }}
                      disabled={getDisabled()}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
});

ReSimpleDialog.displayName = 'ReSimpleDialog';

ReSimpleDialog.propTypes = {
  id: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disableConfirm: PropTypes.bool,
  onCancel: PropTypes.func,
  onOpen: PropTypes.func,
  titleStyle: PropTypes.object,
  customButtonAction: PropTypes.element,
  confirmButton: PropTypes.bool,
};

export default ReSimpleDialog;
