import { LSname } from 'localstorage';

export const getLocalStorage = (key, raw) => {
  const item = localStorage.getItem(key);
  if (raw) return item;
  if (item === 'undefined') return null;
  return item ? JSON.parse(item) : item;
};

export const getLSI18N = () => {
  return getLocalStorage(LSname.i18n, true);
};

export const getLSPermission = () => {
  return getLocalStorage(LSname.permission);
};

export const getLSDevlog = () => {
  return getLocalStorage(LSname.devlog) || {};
};

export const getLSBusinesses = () => {
  const data = getLocalStorage(LSname.business);
  if (Array.isArray(data)) return data;
  return [];
};

export const getLSOutlets = () => {
  return getLocalStorage(LSname.outlets) || [];
};

export const getLSCredential = () => {
  return getLocalStorage(LSname.credential);
};

export const getLSSession = () => {
  return getLocalStorage(LSname.session);
};

export const getLSStockAdjust = () => {
  return getLocalStorage(LSname.stockAdjust);
};

export const getLSStockAudit = () => {
  return getLocalStorage(LSname.stockAudit);
};

export const getLSRegion = () => {
  return getLocalStorage(LSname.region) || [];
};

export const getLSBusinessCategory = () => {
  return getLocalStorage(LSname.businessCategory) || [];
};

export const getLSDevMenu = () => {
  return getLocalStorage(LSname.devmenu) || [];
};
