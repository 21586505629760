import { useEffect, useRef } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Toolbar,
  CssBaseline,
  Drawer,
  Grid,
  useMediaQuery,
  Collapse,
  Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ReSimpleTopBar from 'components/ReSimpleTopBar';
import { useDrawerContext } from 'context/DrawerContext';
import DrawerMenuList from 'assets/file/DrawerMenuList.jsx';
import { _ISNOTPRODUCTION, checkIsCredsExist } from 'utils/functions';
import ReSimpleDialog from './ReSimpleDialog';
import DevInfo from './DevInfo';
import brandBuild from 'assets/file/brandBuild.json';
import { getLSCredential, getLSDevMenu } from 'localstorage/getter';
import { PERMIT_HELPER } from 'assets/file/AccessControl';
import { DrawerMenuMobile } from 'assets/file/DrawerMenuList';
import ReLangDropdown from './ReLangDropdown';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.customColor.background.primary,
  },
  drawer: {
    width: theme.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.drawerWidth,
    backgroundColor: theme.palette.primary.main,
  },
  drawerContainer: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    paddingBottom: 50,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  list: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  buildDate: {
    color: theme.customColor.text.secondary,
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
  },
}));

const getFormatDate = () => {
  return _ISNOTPRODUCTION() ? 'DD - MM - YYYY HH:mm' : 'DD - MM - YYYY';
};

const isMenuDisabled = (menu) => {
  if (_ISNOTPRODUCTION()) {
    const devMenu = getLSDevMenu();
    return devMenu.includes(menu.key)
      ? false
      : menu.disabled || !PERMIT_HELPER(menu.permitAccess, menu.subMenu);
  }
  return menu.disabled || !PERMIT_HELPER(menu.permitAccess, menu.subMenu);
};

const isSubMenuDisabled = (menu, subMenu) => {
  if (_ISNOTPRODUCTION()) {
    const devMenu = getLSDevMenu();
    if (devMenu.includes(subMenu.key)) {
      return false;
    }
  }
  if (subMenu.disabled) {
    return true;
  }
  return !PERMIT_HELPER(
    typeof menu.permitAccess === 'undefined' ? subMenu : menu.permitAccess,
  );
};

const PermanentDrawer = () => {
  const classes = useStyles();
  const mediumScreen = useMediaQuery('(max-width:1280px)');
  const tabletScreen = useMediaQuery('(max-width:960px)');
  const mobileScreen = useMediaQuery('(max-width:600px)');
  const { t } = useTranslation();
  const theme = useTheme();
  const _refDevInfo = useRef();

  const {
    getDrawerState,
    isMenuSelected,
    setSelectedMenu,
    setSelectedsubMenu,
    isSubMenuSelected,
    getTitle,
    toggleDrawer,
  } = useDrawerContext();

  useEffect(() => {
    if (!mobileScreen) {
      toggleDrawer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileScreen]);

  const { toggleRightFilter } = useDrawerContext();

  const StyledListItemText = withStyles({
    root: {
      color: theme.customColor.text.secondary,
    },
    primary: {
      fontFamily: 'Poppins-Regular',
    },
  })(ListItemText);

  const onClickMenu = (menu) => {
    checkIsCredsExist();
    let defaultSubMenu = null;
    if (menu.subMenu && Array.isArray(menu.subMenu)) {
      defaultSubMenu = menu.subMenu.find((subMenu) => {
        return subMenu.default;
      });
    }
    if (defaultSubMenu) {
      setSelectedsubMenu(defaultSubMenu.key, menu.key);
    } else {
      setSelectedMenu(menu.key);
    }
  };

  const onClickSubMenu = (subMenu) => {
    checkIsCredsExist();
    setSelectedsubMenu(subMenu.key);
  };

  // REF
  const getDevInfo = () => {
    return _refDevInfo.current || null;
  };

  const openDevInfo = () => {
    return getDevInfo()?.setValue(true);
  };

  const getID = (key) => {
    return `${key.replaceAll('-', '') || 'default'}Page`;
  };

  return (
    <>
      {getDrawerState('showDrawer') ? (
        <Grid
          id="static-drawer"
          className={classes.root}
          style={{
            overflowX: 'hidden',
            height: 0,
          }}>
          <CssBaseline />
          <ReSimpleTopBar
            id={
              getDrawerState('selectedSubMenu')
                ? getDrawerState('selectedSubMenu')
                : getDrawerState('selectedMenu')
            }
            fullWidth
            onClick={() => toggleRightFilter()}
            title={getTitle()}
          />
          <Drawer
            className={classes.drawer}
            anchor="left"
            open={getDrawerState('openDrawer')}
            ModalProps={{ onBackdropClick: (event) => toggleDrawer(event) }}
            variant={mediumScreen ? null : 'permanent'}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <Toolbar />
            <Box className={classes.drawerContainer}>
              <List className={classes.list}>
                {(tabletScreen
                  ? DrawerMenuMobile(DrawerMenuList)
                  : DrawerMenuList
                ).map((menu) => {
                  return (
                    <Box key={menu.key}>
                      <ListItem
                        id={`drawerList-selectMenu-${getID(menu.key)}`}
                        button
                        disabled={isMenuDisabled(menu)}
                        style={{
                          backgroundColor: isMenuSelected(menu.key)
                            ? 'rgba(255, 255, 255, 0.3)'
                            : null,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          onClickMenu(menu);
                        }}>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                          }}>
                          <ListItemIcon
                            id={`drawerIcon-selectMenu-${getID(menu.key)}`}
                            style={{
                              color: theme.customColor.text.secondary,
                              minWidth: 35,
                            }}>
                            {menu.icon}
                          </ListItemIcon>
                          <StyledListItemText
                            primary={
                              menu.key === 'profile'
                                ? getLSCredential()?.fullname ||
                                  t(menu.key).toUpperCase()
                                : t(menu.key).toUpperCase()
                            }
                          />
                          {menu.key === 'profile' && (
                            <ReLangDropdown
                              labelStyle={{
                                color: theme.customColor.text.secondary,
                              }}
                              useCode
                              safeArea="top"
                            />
                          )}
                        </Box>
                      </ListItem>
                      {menu.subMenu && menu.subMenu.length > 0 ? (
                        <Collapse in={isMenuSelected(menu.key)} unmountOnExit>
                          <List className={classes.sublist}>
                            {menu.subMenu.map((subMenu) => {
                              return (
                                <ListItem
                                  id={`drawerList-selectSubMenu-${getID(
                                    subMenu.key,
                                  )}`}
                                  button
                                  disabled={isSubMenuDisabled(menu, subMenu)}
                                  key={subMenu.key}
                                  onClick={() => {
                                    onClickSubMenu(subMenu);
                                  }}>
                                  <ListItemIcon
                                    id={`drawerIcon-selectSubMenu-${getID(
                                      subMenu.key,
                                    )}`}
                                    style={{ minWidth: 35 }}
                                  />
                                  <StyledListItemText
                                    style={{
                                      color: isSubMenuSelected(subMenu.key)
                                        ? theme.palette.secondary.main
                                        : theme.customColor.text.secondary,
                                    }}
                                    primary={t(`${subMenu.key}`)}
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        </Collapse>
                      ) : null}
                    </Box>
                  );
                })}
              </List>
            </Box>

            <Box
              className={classes.buildDate}
              onClick={() => {
                if (_ISNOTPRODUCTION()) openDevInfo();
              }}>
              {_ISNOTPRODUCTION() && getLSCredential()?.app}
              <br />
              {`Version : ${brandBuild.version}`}
              <br />
              {`Build Date : ${
                brandBuild
                  ? `${moment(brandBuild.date).format(getFormatDate())}`
                  : '-'
              }`}
            </Box>
          </Drawer>

          {_ISNOTPRODUCTION() && (
            <ReSimpleDialog
              ref={_refDevInfo}
              id="developerInfo"
              title="Developer Info"
              message={<DevInfo />}
            />
          )}
        </Grid>
      ) : null}
    </>
  );
};

export default PermanentDrawer;
