import { useEffect, useState } from 'react';

import {
  AppBar,
  Grid,
  Typography,
  Button,
  Hidden,
  useMediaQuery,
  IconButton,
  Box,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { ReactComponent as BrandLogo } from 'assets/images/logo/brand-logo-white.svg';
import ReselectItem from 'components/ReSelectItem';
import { Phone, Menu } from '@material-ui/icons';
import Icon from '@material-ui/core/Icon';
import { useDrawerContext } from 'context/DrawerContext';
import { useTranslation } from 'react-i18next';
import { getLSBusinesses, getLSOutlets } from 'localstorage/getter';
import {
  brandName,
  capitalizeFirstString,
  getMainPagePath,
} from 'utils/functions';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: 65,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1301,
  },
  menuIcon: {
    padding: 0,
    margin: 0,
    color: theme.customColor.text.secondary,
  },
  welcomeAndContact: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 50,
  },
  phoneIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingRight: 10,
  },
}));

const Logo = () => (
  <BrandLogo
    style={{
      width: 120,
      float: 'left',
    }}
  />
);

const ReSimpleTopBar = ({
  rightComponent,
  fullWidth,
  title,
  VerifyPage,
  onClick,
  id,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const mediumScreen = useMediaQuery('(max-width:1280px)');
  const mobileScreen = useMediaQuery('(max-width:600px)');
  const {
    getDrawerState,
    toggleDrawer,
    getSelectedBusiness,
    getSelectedOutlet,
    setSelectedOutlet,
    setSelectedBusiness,
  } = useDrawerContext();

  const [localState, setLocalState] = useState({
    outlets: [],
    businessInformation: [],
  });

  const updateLocalState = (newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const normalizeOutlet = (outlets) => {
    return outlets.map((item) => ({
      label: item.name,
      // eslint-disable-next-line no-underscore-dangle
      value: item.id.toString(),
      business: item.business,
    }));
  };

  const getInitiateData = async (param) => {
    const outletData = getLSOutlets();
    const businessData = getLSBusinesses()?.filter((item) => item.has_outlet);
    const normalizeoutlet = !outletData.error
      ? normalizeOutlet(outletData)
      : [];

    const dataToUpdate = {};
    if (param === 'business') {
      dataToUpdate.businessInformation = businessData;
    } else if (param === 'outlets') {
      dataToUpdate.outlets = normalizeoutlet;
    } else {
      dataToUpdate.businessInformation = businessData;
      dataToUpdate.outlets = normalizeoutlet;
    }

    updateLocalState({
      ...dataToUpdate,
    });
  };

  const getOutlets = () => {
    const business = getSelectedBusiness();
    let outletsList = [];
    if (business && localState.outlets.length > 0) {
      outletsList = localState.outlets.filter(
        (item) => item.business.id.toString() === business.toString(),
      );
      if (getDrawerState('useAllOutletFilter')) {
        outletsList.unshift({ value: 'all', label: t('allOutlet') });
      }
    }
    return outletsList;
  };

  useEffect(() => {
    getInitiateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const outletsValue = () => {
    // jika tidak menggunakan filter Semua Outlet & outlet masih kosong atau masih "all"
    // replace dengan outlet array pertama
    if (
      !getDrawerState('useAllOutletFilter') &&
      (!getSelectedOutlet() || getSelectedOutlet() === 'all')
    ) {
      if (Array.isArray(getOutlets()) && getOutlets().length > 0) {
        setSelectedOutlet(getOutlets()[0]?.value);
        return getOutlets()[0]?.value;
      }
    }
    return getSelectedOutlet();
  };

  window.addEventListener('storage', (event) => {
    if (event.key === 'business') {
      getInitiateData('business');
    } else if (event.key === 'outlets') {
      getInitiateData('outlets');
    }
  });

  useEffect(() => {
    if (!mobileScreen) {
      toggleDrawer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileScreen]);

  const handleClickOutletFilter = (value) => {
    setSelectedOutlet(value);
  };

  const handleClickBusinessFilter = (value) => {
    setSelectedBusiness(value);
  };

  const getID = (paramText) => {
    const getParamText = capitalizeFirstString(paramText || '');
    const componentLocation = id ? id.replaceAll('-', '') : 'default';

    return `filter${getParamText}-${componentLocation}Page`;
  };

  return (
    <Box>
      <AppBar
        position={fullWidth ? 'fixed' : 'static'}
        className={classes.appBar}>
        <Grid container style={{ padding: `0px ${theme.spacing(2.5)}px` }}>
          {/* LOGO */}
          <Grid
            item
            style={{
              // tidak bisa di pindah ke makestyle, depend ke variable mediumScreen
              alignSelf: 'center',
              width: mediumScreen ? 50 : theme.drawerWidth,
            }}>
            {mediumScreen ? (
              <IconButton
                onClick={(event) => {
                  toggleDrawer(event);
                }}
                className={classes.menuIcon}>
                <Menu style={{ fontSize: 34 }} />
              </IconButton>
            ) : (
              <a href={getMainPagePath()}>
                <Logo />
              </a>
            )}
          </Grid>

          {/* RIGHT */}
          {VerifyPage ? (
            <Grid style={{ width: `calc(100% - ${theme.drawerWidth}px)` }}>
              {rightComponent || (
                <Grid
                  container
                  spacing={5}
                  className={classes.welcomeAndContact}>
                  <Grid item>
                    <Typography variant="caption">
                      Halo, {brandName()}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid item className={classes.phoneIcon}>
                      <Phone style={{ fontSize: 12 }} />
                    </Grid>
                    <Typography variant="caption">2300 456</Typography>
                  </Grid>
                  <Grid item>
                    <Icon>support</Icon>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              // tidak bisa di pindah ke makestyle karena depend ke variable
              style={{
                width: `calc(100% - ${
                  mediumScreen ? 50 : theme.drawerWidth
                }px)`,
              }}
              container>
              <Grid
                item
                xs={10}
                md={11}
                lg={4}
                style={{ display: 'flex', alignItems: 'center' }}>
                {getDrawerState('openDrawer') && mediumScreen ? (
                  <Logo />
                ) : (
                  <Typography
                    variant={mobileScreen ? 'h5' : 'h4'}
                    style={{ fontFamily: 'Poppins-Medium' }}>
                    {title || null}
                  </Typography>
                )}
              </Grid>
              {getDrawerState('withFilter') && (
                <Grid item xs={2} md={1} lg={8} container>
                  <Hidden mdDown>
                    <Grid
                      container
                      lg={11}
                      item
                      direction="row"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}>
                      {getDrawerState('bisnisOutletFilter') && (
                        <>
                          <Grid
                            item
                            xs={4}
                            style={{ marginRight: theme.spacing(4) }}>
                            <ReselectItem
                              id={getID('business')}
                              data-testid="business"
                              IconName="business_center"
                              onChange={(val) => {
                                handleClickBusinessFilter(val);
                              }}
                              disabled={
                                !localState.businessInformation.length > 0
                              }
                              ListItem={localState.businessInformation}
                              value={getDrawerState('selectedBusiness')}
                            />
                          </Grid>
                          {getDrawerState('outletFilter') && (
                            <Grid
                              item
                              xs={4}
                              style={{ marginRight: theme.spacing(4) }}>
                              <ReselectItem
                                id={getID('outlet')}
                                data-testid="outlet"
                                IconName="storefront"
                                onChange={(val) => {
                                  handleClickOutletFilter(val);
                                }}
                                disabled={!getOutlets().length > 0}
                                value={outletsValue()}
                                ListItem={getOutlets()}
                              />
                            </Grid>
                          )}
                        </>
                      )}
                    </Grid>
                  </Hidden>
                  {((mediumScreen && getDrawerState('bisnisOutletFilter')) ||
                    getDrawerState().filterButton) && (
                    <Grid
                      xs={12}
                      lg={1}
                      item
                      style={{ justifyContent: 'flex-end' }}>
                      <Button
                        id={`button-${getID()}`}
                        onClick={onClick}
                        color="inherit">
                        <Grid item>
                          <Grid style={{ marginBottom: -10 }}>
                            <Icon
                              id={`buttonIcon-${getID()}`}
                              style={{ fontSize: 24 }}>
                              filter_alt
                            </Icon>
                          </Grid>
                          <Typography
                            id={`buttonText-${getID()}`}
                            variant="caption">
                            Filter
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </AppBar>
    </Box>
  );
};

ReSimpleTopBar.displayName = 'ReSimpleTopBar';

ReSimpleTopBar.propTypes = {
  id: PropTypes.string,
  rightComponent: PropTypes.element,
  fullWidth: PropTypes.bool,
  title: PropTypes.string,
  VerifyPage: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ReSimpleTopBar;
