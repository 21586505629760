import { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import DrawerProvider from 'context/DrawerContext';
import { logInfo } from 'utils/logging';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SentryInfo, SentryLevel, ignorableErrors } from 'utils/sentry';
import { _ISNOTPRODUCTION, brandName } from 'utils/functions';
import ContentProvider from './context/ContentContext';
import ThemeStyles from './ThemeStyles';
import brandBuild from 'assets/file/brandBuild.json';
import DevTools from 'components/DevTools';
import Routes from './Routes.jsx';
import { listenerPostMessageLogin, postMessageKeys } from './utils/postMessage';
import { setLSCredential } from './localstorage/setter';
import { getLSCredential } from './localstorage/getter';
import { getPermission } from './assets/file/ACLOptions';
import { sentSentryError } from './utils/sentry';

const brandRelease = `BackOffice_${import.meta.env.MODE.toUpperCase()}@${
  brandBuild.version
}`;

logInfo(`MODE : ${import.meta.env.MODE.toUpperCase()}`);
logInfo(`SENTRY BUILD INFO : ${brandRelease}`);

const getClientUserAcl = async () => {
  try {
    if (getLSCredential() && getLSCredential()?.hit_access) {
      await getPermission();
    }
  } catch (e) {
    sentSentryError(e, SentryLevel.Error);
  }
};

function App() {
  const handleMessage = (result) => {
    if (result && result?.key === postMessageKeys.sendPMCredential) {
      setLSCredential(result?.data);
      getClientUserAcl();
    }
  };

  useEffect(() => {
    if (_ISNOTPRODUCTION()) {
      document.title = `${import.meta.env.VITE_TITLE} - ${brandName(true)} ${
        document.title
      }`;
    } else {
      document.title = `${brandName(true)}`;
    }

    if (
      !import.meta.env.MODE.toLowerCase().includes('local') &&
      !import.meta.env.MODE.toLowerCase().includes('development')
    ) {
      Sentry.init({
        dsn: 'https://586dea0d78354246a701240dc8d47dc9@sentry.mgc.pw/6',
        integrations: [new BrowserTracing()],
        release: `${brandRelease}`,
        tracesSampleRate: 1.0,
        environment: import.meta.env.VITE_SENTRY_ENV || 'LOCAL',
        ignoreErrors: ignorableErrors,
        initialScope: (scope) => {
          scope.setTags({ errorType: 'UNHANDLED ERROR' });
          scope.setLevel(SentryLevel.Fatal);
          scope.setTags(SentryInfo().tags);
          scope.setUser(SentryInfo().user);

          return scope;
        },
      });
    }
  }, []);

  listenerPostMessageLogin(handleMessage);

  return (
    <div className="App">
      <ThemeProvider theme={ThemeStyles}>
        <ContentProvider useSuspense={false}>
          <Router>
            <DrawerProvider>
              {_ISNOTPRODUCTION() && <DevTools />}
              <Routes />
            </DrawerProvider>
          </Router>
        </ContentProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
