import React, { useState, useContext, useRef } from 'react';

import { withTranslation } from 'react-i18next';
import { postMessageKeys, senderPostMessageReport } from 'utils/postMessage';
import ReSnackBar from 'components/ReSnackBar';
import PropTypes from 'prop-types';

const salesPath = import.meta.env.VITE_REPORT_UI;

/* ===========> BACA DULU SEBELUM EDIT <===========

TOLONG JANGAN MASUKKAN RANDOM VARIABLE KE SINI, ATAU KONSULTASIKAN KE MAINTAINER BO

===========> BACA DULU SEBELUM EDIT <=========== */

export const ContentContext = React.createContext([{}, () => {}]);

const initialSnackBar = {
  show: false,
  type: '', // error, success, warning
  message: '',
};

const ContentProvider = ({ t, children }) => {
  const _refGeneralReport = useRef();
  const [state, setState] = useState({
    snackbar: initialSnackBar,

    // context right Filter
    rightDrawerShow: false,
    disableButtonFilter: true,
    filterItem: [],

    activeFilterItem: [
      {
        value: true,
        label: 'Aktif',
      },
      {
        value: false,
        label: 'Non Aktif',
      },
    ],
  });

  const [loading, setloading] = useState({
    loading: false,
    topPosition: false,
    label: '',
    location: '',
  });

  const setContentState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getContentState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const onCloseSnackbarHandler = () => {
    setContentState({ snackbar: initialSnackBar });
  };

  const openSuccessSnackBar = (message) => {
    setContentState({
      snackbar: {
        show: true,
        type: 'success',
        message,
      },
    });
  };

  const openErrorSnackBar = (message) => {
    // default error, general error
    // jika props message adalah string, maka pakai message dari props
    // jika props message adalah object, cek errornya string apa bukan
    // dan juga cek apakah ada message nya di object tersebut
    // jika ada, pake message itu (dari server)

    let errorMessage = t('error.generalError');
    if (typeof message === 'string') {
      errorMessage = message;
    } else if (
      typeof message === 'object' &&
      typeof message?.error === 'string' &&
      message?.message
    ) {
      errorMessage = message?.message;
    }

    setContentState({
      snackbar: {
        show: true,
        type: 'error',
        message: errorMessage,
      },
    });
  };

  const openWarningSnackBar = (message) => {
    setContentState({
      snackbar: {
        show: true,
        type: 'warning',
        message,
      },
    });
  };

  const setLoading = (value) => {
    switch (value.loading) {
      case true:
        // Jika sudah loading, jangan ditimpa loading lain
        if (!loading.loading) {
          setloading({
            loading: value.loading,
            topPosition: value.topPosition ? value.topPosition : false,
            label: value.label ? value.label : '',
            location: value.location,
          });
        } else {
          break;
        }
        break;
      case false:
        // jika lokasi loadingnya sama atau loading state ternyata sudah false,
        // maka diperbolehkan setstate
        if (
          value.location === loading.location ||
          (!loading.loading && !value.loading)
        ) {
          setloading({
            loading: value.loading,
            topPosition: false,
            label: '',
            location: '',
          });
        } else {
          break;
        }
        break;
      default:
        break;
    }
  };

  const getLoading = () => {
    return loading;
  };

  const toggleRightFilter = () => {
    setContentState({ rightDrawerShow: !state.rightDrawerShow });
  };

  const setItemFilter = async (itemSelected) => {
    const items = state.filterItem;
    items.push(itemSelected);
    setContentState({ filterItem: items });
  };

  const setDisabledButtonFilter = () => {
    setContentState({ disableButtonFilter: !state.disableButtonFilter });
  };

  const sendCustomPM = (data = {}) => {
    // only accept object
    if (data) {
      senderPostMessageReport(
        _refGeneralReport.current,
        postMessageKeys.custom,
        data,
      );
    }
  };

  const sendLangPM = (data) => {
    // only accept object
    if (data) {
      senderPostMessageReport(
        _refGeneralReport.current,
        postMessageKeys.lang,
        data,
      );
    }
  };

  return (
    <ContentContext.Provider
      value={{
        getContentState,
        setContentState,
        openSuccessSnackBar,
        openErrorSnackBar,
        openWarningSnackBar,
        toggleRightFilter,
        setDisabledButtonFilter,
        setItemFilter,
        setLoading,
        getLoading,
        sendCustomPM,
        sendLangPM,
      }}>
      <ReSnackBar
        show={state.snackbar.show}
        type={state.snackbar.type || 'success'}
        onClose={() => onCloseSnackbarHandler()}
        message={state.snackbar.message || 'SET MESSAGE PLEASE'}
      />
      {children}
      <iframe
        ref={_refGeneralReport}
        src={`${salesPath}`}
        title="GENERAL IFRAME REPORT"
        style={{ display: 'none' }}
        frameBorder="none"
      />
    </ContentContext.Provider>
  );
};

export const useContentContext = () => {
  const value = useContext(ContentContext);
  if (value == null) {
    throw new Error('useContentContext() called outside of a PosProvider?');
  }
  return value;
};

ContentProvider.propTypes = {
  children: PropTypes.element,
  t: PropTypes.func,
};

export default withTranslation()(ContentProvider);
