import { useState, useCallback, useImperativeHandle, forwardRef } from 'react';

import {
  Button,
  Typography,
  Icon,
  useTheme,
  Grid,
  CircularProgress,
  Tooltip,
  Box,
} from '@material-ui/core';

import ThemeStyles from 'ThemeStyles';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const StyledButton = withStyles({
  root: {
    '&:disabled': {
      backgroundColor: `${ThemeStyles.palette.disabled.main} !important`,
      color: ThemeStyles.palette.disabled.text,
    },
    textTransform: 'none',
  },
  outlined: {
    backgroundColor: 'white',
    color: ThemeStyles.palette.secondary.main,
    borderColor: ThemeStyles.palette.secondary.main,
    '&:hover': {
      borderColor: ThemeStyles.palette.secondary.main,
      backgroundColor: '#f6f5f7',
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  iconText: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  customToolTip: {
    backgroundColor: theme.customColor.background.blackOpacity,
  },
}));

/*
prop list
- variant
- minWidth
- width
- style
- color
- onClick
- wait
- disabled
- leftIcon
- label
- noLabel
- withShadow
- circleWithSize
- iconColor
- checkIcon
- varianText
*/

const TooltipButton = (props) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{ tooltip: classes.customToolTip }}
      title={
        <Typography
          variant="body1"
          style={{
            fontFamily: 'Poppins-Regular',
          }}>
          {props.tooltipTitle}
        </Typography>
      }>
      <Box component="span">{props.children}</Box>
    </Tooltip>
  );
};

TooltipButton.propTypes = {
  tooltipTitle: PropTypes.string,
  children: PropTypes.element,
};

const OvalButtonComponent = (paramProps) => {
  if (paramProps.useTooltip) {
    return <TooltipButton {...paramProps}>{paramProps.children}</TooltipButton>;
  }

  return paramProps.children;
};

const WaitCircularProgress = (props) => {
  if (props.wait) {
    return (
      <CircularProgress
        size={16}
        style={{ padding: 0, margin: 0, marginLeft: 8 }}
      />
    );
  }
  return null;
};

WaitCircularProgress.propTypes = {
  wait: PropTypes.bool,
};

const getLeftIcon = (props, theme) => {
  if (props.leftIcon) {
    return (
      <Icon
        id={`buttonIcon-${props['id'] || 'default'}`}
        style={{
          marginRight: props.noLabel ? undefined : theme.spacing(1),
          color: props.iconColor,
        }}>
        {props.leftIcon}
      </Icon>
    );
  }
  return null;
};

const OvalButton = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const checkDisable = () => {
    if (typeof props.permission === 'boolean') {
      return props.disabled || !props.permission;
    }
    if (props.useDisable) {
      return props.wait || props.state?.disabled;
    }
    return props.disabled || props.wait;
  };

  return (
    <StyledButton
      type={props.type || 'button'}
      size={props.size}
      id={`button-${props['id'] || 'default'}`}
      data-value={props['data-value']}
      className={StyledButton.outlined}
      // tidak bisa dipindah ke makestyle
      style={{
        backgroundColor: props.baseColor,
        minWidth: props.circleWithSize ? 0 : props.minWidth,
        width: props.circleWithSize
          ? props.circleWithSize
          : props.width || '100%',
        height: props.circleWithSize,
        minHeight: props.minHeight ? props.minHeight : null,
        borderRadius: props.circleWithSize,
        boxShadow: props.withShadow
          ? 'rgba(0, 0, 0, 0.16) 0px 3px 6px'
          : undefined,
        ...props.style,
      }}
      variant={props.variant || 'contained'}
      color={props.color || 'primary'}
      onClick={props.onClick}
      disabled={checkDisable()}>
      {getLeftIcon(props, theme)}
      <Grid container direction="row" style={{ justifyContent: 'center' }}>
        {props.noLabel ? null : (
          <Grid
            id={`buttonGrid-${props['id'] || 'default'}`}
            item
            xs={props.checkIcon ? 11 : 12}
            className={classes.iconText}>
            {props.centerIcon ? (
              <Icon
                id={`buttonIcon-${props['id'] || 'default'}`}
                style={{
                  marginRight: theme.spacing(1),
                  color: props.iconColor,
                  ...props.centerIconStyle,
                }}>
                {props.centerIcon}
              </Icon>
            ) : null}
            <Typography
              id={`buttonText-${props['id'] || 'default'}`}
              variant={props.variantText}
              style={props.textStyle}
              noWrap={props.noWrapText}>
              {props.label || 'TOMBOL'}
            </Typography>
            <WaitCircularProgress {...props} />
          </Grid>
        )}
        {props.checkIcon ? (
          <Grid
            id={`buttonGrid-${props['id'] || 'default'}`}
            item
            xs={1}
            style={{ display: 'flex', alignItems: 'center' }}>
            <Icon
              id={`buttonIcon-${props['id'] || 'default'}`}
              style={{ fontSize: 13, color: 'white' }}>
              check_circle
            </Icon>
          </Grid>
        ) : null}
      </Grid>
    </StyledButton>
  );
};

OvalButton.propTypes = {
  id: PropTypes.string,
  permission: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  useDisable: PropTypes.bool,
  wait: PropTypes.bool,
  withShadow: PropTypes.bool,
  noLabel: PropTypes.bool,
  checkIcon: PropTypes.bool,
  centerIcon: PropTypes.string,
  noWrapText: PropTypes.bool,
  state: PropTypes.object,
  size: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  'data-value': PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  baseColor: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  variantText: PropTypes.string,
  style: PropTypes.object,
  centerIconStyle: PropTypes.object,
  textStyle: PropTypes.object,
  circleWithSize: PropTypes.number,
  minHeight: PropTypes.number,
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
};

const ReOvalButton = forwardRef((props, ref) => {
  const [localState, setLocalState] = useState({
    disabled: props.useDisable || false,
  });

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const updateLocalState = useCallback((newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  }, []);

  useImperativeHandle(ref, () => ({
    submitButton(submitFunction) {
      submitFunction();
      if (props.onFinishSubmit) {
        props.onFinishSubmit();
      }
    },
    setIsSubmitable(value) {
      updateLocalState({ disabled: !value });
    },
  }));

  return (
    <div>
      <OvalButtonComponent {...props}>
        <OvalButton state={getLocalState()} {...props} />
      </OvalButtonComponent>
    </div>
  );
});

ReOvalButton.displayName = 'ReOvalButton';

ReOvalButton.propTypes = {
  id: PropTypes.string,
  useDisable: PropTypes.bool,
  onFinishSubmit: PropTypes.func,
};

export default ReOvalButton;
