import { getLSI18N, getLSPermission } from 'localstorage/getter';
import { setLSPermission } from 'localstorage/setter';
import * as Mongo from 'mongoservices/setup';

const lang = getLSI18N();

export const getPermission = async () => {
  let data = getLSPermission();
  if (
    !data ||
    (Array.isArray(data) && data.length === 0) ||
    (data && !Array.isArray(data))
  ) {
    let aclData = getLSPermission();
    const isExist = !!aclData && Array.isArray(aclData);
    if (!isExist) {
      aclData = await Mongo.Call(Mongo.realmFunction.clientUserAcl, 'GET');

      setLSPermission(aclData);
    }
    data = aclData;
  }
  return data;
};

const OptionList = async (type) => {
  const permission = await getPermission();

  // memasukkan data sesuai type (BO/POS) dan sesuai side nya
  let leftSide = [];
  let rightSide = [];

  permission.forEach((acl) => {
    // inisiasi current side
    let currentSide = acl.side === 'left' ? leftSide : rightSide;
    // inisiasi value dari current acl
    const currentValue = {
      id: acl.id,
      label: acl.label[lang],
      info: acl.info ? acl.info[lang] : undefined,
    };

    if (acl.type === type) {
      // jika acl mempunyai parent, maka buat dulu parent nya
      let isParentExist = false;
      if (acl.parent) {
        // jika parentnya sudah ada di sidenya, maka skip
        // jika belum ada buat dulu parentnya
        isParentExist = currentSide.some((side) => side.id === acl.parent);

        if (!isParentExist) {
          currentSide = [
            ...currentSide,
            {
              id: acl.parent,
              label: acl.parent,
              parent: true,
              info: acl.info ? acl.info[lang] : undefined,
              subList: [currentValue],
            },
          ];
        } else {
          const parentIndex = currentSide.findIndex(
            (side) => side.id === acl.parent,
          );
          currentSide[parentIndex].subList = [
            ...currentSide[parentIndex].subList,
            currentValue,
          ];
        }
      } else {
        // jika yatim, maka append langsung
        currentSide = [...currentSide, currentValue];
      }
    }

    if (acl.side === 'left') {
      leftSide = currentSide;
    } else {
      rightSide = currentSide;
    }
  });

  return {
    leftSide: [...leftSide],
    rightSide: [...rightSide],
  };
};

const ACLKeys = async () => {
  // fungsi ACLKeys sebisa mungkin tidak menggunakan async await
  // karena fungsi ini perlu di akses langsung tanpa harus await
  // disengaja menggunakan then
  const permission = await getPermission();
  return permission.map((acl) => acl.id) || [];
};

export const normalizeACLData = async (type, grantedData) => {
  const aclData = await OptionList(type);
  const currentData = [...aclData.leftSide, ...aclData.rightSide];

  return currentData
    .map((list) => {
      const localList = { ...list };
      if (list.subList && list.subList.length > 0) {
        const newSubList = localList.subList.filter((sub) =>
          grantedData.includes(sub.id),
        );

        if (newSubList.length > 0) {
          localList.items = [...newSubList];
          delete localList.subList;
          return localList;
        }
      } else {
        if (grantedData.includes(localList.id)) {
          return localList;
        }
      }
      return null;
    })
    .filter((list) => list);
};

export { OptionList, ACLKeys };
