import * as Sentry from '@sentry/react';
import {
  getLSBusinesses,
  getLSCredential,
  getLSOutlets,
} from 'localstorage/getter';
import { CookieName, getCookie } from './cookie';
import { checkEmailFormat } from './validation';

export const SentryLevel = {
  Fatal: 'fatal',
  Critical: 'critical',
  Error: 'error',
  Warning: 'warning',
  Log: 'log',
  Info: 'info',
  Debug: 'debug',
};

export const SentryInfo = () => {
  const credential = getLSCredential();
  const selectedBusiness = getCookie(CookieName.SELECTED_BUSINESS);
  const selectedOutlet = getCookie(CookieName.SELECTED_OUTLET);

  const tagsInfo = {};
  const userInfo = {
    id: credential?.id,
    fullname: credential?.fullname,
  };

  if (credential?.email && checkEmailFormat(credential?.email)) {
    tagsInfo.email = credential.email;
    userInfo.email = credential.email;
  } else if (credential?.username) {
    tagsInfo.username = credential.username;
    userInfo.username = credential.username;
  }

  if (selectedBusiness) {
    const allBusiness = getLSBusinesses();
    const findBusiness =
      Array.isArray(allBusiness) &&
      allBusiness.find((business) => business.id === selectedBusiness);
    if (findBusiness) {
      tagsInfo.businessID = findBusiness.id;
      tagsInfo.businessName = findBusiness.name;
      userInfo.businessID = findBusiness.id;
      userInfo.businessName = findBusiness.name;
    }
  }

  if (selectedOutlet) {
    if (selectedOutlet !== 'all') {
      const allOutlets = getLSOutlets();
      const findOutlet =
        Array.isArray(allOutlets) &&
        allOutlets.find((outlet) => outlet.id === selectedOutlet);
      if (findOutlet) {
        tagsInfo.outletID = findOutlet.id;
        tagsInfo.outletName = findOutlet.name;
        userInfo.outletID = findOutlet.id;
        userInfo.outletName = findOutlet.name;
      }
    } else {
      userInfo.outletName = 'ALL OUTLET SELECTED';
    }
  }

  return { tags: tagsInfo, user: userInfo };
};

export const sentSentryError = (error, level = SentryLevel.Info) => {
  if (
    !import.meta.env.MODE.toLowerCase().includes('local') &&
    !import.meta.env.MODE.toLowerCase().includes('development')
  ) {
    Sentry.withScope((scope) => {
      scope.setLevel(level);
      scope.setUser(SentryInfo().user);
      scope.setTags(SentryInfo().tags);

      // The exception has the event level set by the scope (info).
      Sentry.captureException(
        error ||
          new Error(
            'This is Default Error cause Property Error Message is not passed',
          ),
      );
    });
  }
};

export const ignorableErrors = [
  'Failed to fetch', // biasanya gagal hit realm
  'Request failed', // biasanya gagal hit realm
  'Maximum update depth exceeded', // terlalu dalam untuk disolve (tidak major mempengaruhi app)
];
