import { Box, Popover, Typography, useTheme } from '@material-ui/core';
import { useEffect, useState } from 'react';
import PopupState, { bindPopover, bindHover } from 'material-ui-popup-state';
import masterLang from 'assets/file/languages';
import { useTranslation } from 'react-i18next';
import { getLSCredential, getLSI18N } from 'localstorage/getter';
import PropTypes from 'prop-types';
import { useContentContext } from 'context/ContentContext';
import { setLSCredential } from 'localstorage/setter';

const handleChangeLang = (
  e,
  mlang,
  currentLang,
  i18n,
  setCurrentLang,
  sendLangPM,
) => {
  e.preventDefault();
  if (mlang.code !== currentLang) {
    i18n.changeLanguage(mlang.code);
    setCurrentLang(mlang.code);
    sendLangPM(mlang.code);
  }
};

const ReLangDropdown = (props) => {
  const theme = useTheme();
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(
    getLSI18N() == 'null' ? 'id' : getLSI18N(),
  );
  const thisLang = masterLang.find((x) => x.code === currentLang);
  const { sendLangPM } = useContentContext();

  const getLangLabel = () => {
    let langLabel = `${
      props.useShort ? thisLang.short : thisLang.label
    } (${thisLang.code.toUpperCase()})`;

    if (props.useCode) {
      langLabel = `${thisLang.code.toUpperCase()} ▼`;
    }

    return langLabel;
  };

  useEffect(() => {
    const credentials = getLSCredential();
    if (credentials.lang) {
      i18n.changeLanguage(credentials.lang);
      setCurrentLang(credentials.lang);

      delete credentials.lang;
      setLSCredential(credentials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <Box {...bindHover(popupState)}>
            <Typography
              variant="caption"
              style={{
                textAlign: 'center',
                verticalAlign: 'center',
                fontFamily: 'Poppins-Regular',
                color: theme.customColor.text.primary,
                ...props.labelStyle,
              }}>
              {getLangLabel()}
            </Typography>
          </Box>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: props.safeArea || 'bottom',
              horizontal: 'left',
            }}>
            <Box style={{ display: 'flex', flexDirection: 'column' }} p={1}>
              {masterLang.map((mlang) => {
                return (
                  <Box
                    key={mlang.code}
                    p={1}
                    style={{
                      cursor: 'pointer',
                      backgroundColor:
                        mlang.code === currentLang
                          ? theme.palette.disabled.main
                          : 'inherit',
                    }}
                    onClick={(e) => {
                      handleChangeLang(
                        e,
                        mlang,
                        currentLang,
                        i18n,
                        setCurrentLang,
                        sendLangPM,
                      );
                    }}>
                    <Typography
                      variant="caption"
                      style={{
                        textAlign: 'center',
                        verticalAlign: 'center',
                        fontFamily: 'Poppins-Regular',
                        color: theme.customColor.text.primary,
                      }}>
                      {`${mlang.label} (${mlang.code.toUpperCase()})`}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
};

ReLangDropdown.propTypes = {
  useShort: PropTypes.bool,
  useCode: PropTypes.bool,
  labelStyle: PropTypes.object,
  safeArea: PropTypes.string,
};

export default ReLangDropdown;
