/* eslint-disable */
import { folderName } from 'assets/file/imageDestination';
import moment from 'moment-timezone';
import Compress from 'compress.js';
import { isDecimal, validatePositiveNumber } from './validation';
import { SentryLevel, sentSentryError } from './sentry';
import { setLSSession } from 'localstorage/setter';
import {
  getLSBusinesses,
  getLSCredential,
  getLSOutlets,
} from 'localstorage/getter';
import * as Mongo from 'mongoservices/setup';
import { postMessageKeys, senderPostMessageLogin } from './postMessage';

// GET QUERY STRING FROM THE URL ex: /verify?name=john => ?name=john
export const getLocationQuery = (location, parameter) => {
  if (parameter) {
    return new URLSearchParams(location.search).get(parameter);
  }
  return location.search;
};

export const multiFilter = (itemList, filters) => {
  const newFilter = {};
  for (let i = 0; i < Object.keys(filters).length; i++) {
    if (Object.values(filters)[i].length > 0) {
      newFilter[Object.keys(filters)[i]] = Object.values(filters)[i];
    }
  }
  return itemList.filter((product) => {
    return Object.entries(newFilter).every(([filterProperty, filterValues]) => {
      switch (Object.prototype.toString.call(product[filterProperty])) {
        case '[object Object]':
          return Object.entries(filterValues).every(
            ([extFilterProperty, extFilterValue]) => {
              return (
                new Map(Object.entries(product[filterProperty])).get(
                  extFilterProperty,
                ) === extFilterValue
              );
            },
          );

        case '[object Array]':
          return product[filterProperty].some((productValue) => {
            return filterValues.includes(productValue);
          });

        default:
          return filterValues.includes(product[filterProperty]);
      }
    });
  });
};

export const getBusinessIdByOutletId = async (outlet_id = '') => {
  // BECAUSE BE NEVER RETURN BUSINESS INFORMATION EVERY FETCH, SO THIS IS HAVE TO BE SOLVE IN FE
  const outletData = getLSOutlets();

  if (outletData && outletData.length > 0) {
    let currentOutlet = outletData.filter(
      (outlet) => outlet.id.toString() === outlet_id.toString(),
    );

    return currentOutlet[0].business.id.toString();
  }
  return null;
};

export const getBusinessNameByOutletId = async (outlet_id = '') => {
  // BECAUSE BE NEVER RETURN BUSINESS INFORMATION EVERY FETCH, SO THIS IS HAVE TO BE SOLVE IN FE
  const outletData = getLSOutlets();
  const businessData = getLSBusinesses();

  if (outletData && outletData.length > 0) {
    let currentOutlet = outletData.filter(
      (outlet) => outlet.id.toString() === outlet_id.toString(),
    );

    let currentBusinessIndex = businessData.findIndex(
      (business) =>
        business.id.toString() === currentOutlet[0].business.id.toString(),
    );

    return businessData[currentBusinessIndex].name;
  }
  return null;
};

export const getDiffTime = (timestamp, diffFormat) => {
  let dateFormat = 'YYYY-MM-DD HH:mm:ss';
  let oldTime = moment(+timestamp).format(dateFormat);
  let currentTime = moment(new Date()).format(dateFormat);
  let diff = moment(currentTime).diff(oldTime, diffFormat || 'minutes');
  return diff;
};

export const updateSession = () => {
  const currentTime = new Date().getTime();
  setLSSession(currentTime);
};

export const convertNumber = (number, noMathRound) => {
  const localNumber = (n) => {
    let result = n;
    if (typeof n === 'string') {
      if (validatePositiveNumber(n)) {
        result = n;
      } else {
        result = +n;
      }
    }
    return result;
  };

  const locale = 'id-ID';
  const num = number || 0;
  // jika pengen ada koma di belakang angka contoh 10.000,02 maka pakai code dibawah ini
  // number.toLocaleString('id-ID', { minimumFractionDigits: 2 });
  if (noMathRound) {
    let options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    };
    return localNumber(num).toLocaleString(
      locale,
      isDecimal(localNumber(num)) ? options : undefined,
    );
  }
  return Math.round(localNumber(num)).toLocaleString(locale);
};

export const capitalizeFirstString = (str) => {
  const string = typeof str === 'string' ? str : '';
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const pascalCaseString = (string = '') => {
  let regex = /(\w)(\w*)/g;
  return string.replace(regex, function (_, g1, g2) {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

export const sumArrayValue = (array, key) => {
  let sum = array.map((arr) => arr[key]).reduce((a, c) => +a + +c);
  return sum;
};

export const resizeImageFn = async (file) => {
  // https://stackoverflow.com/questions/61740953/reactjs-resize-image-before-upload
  const compress = new Compress();
  const resizedImage = await compress.compress([file], {
    size: 0.3, // the max size in MB, defaults to 2MB
    quality: 1, // the quality of the image, max is 1,
    maxWidth: 1000, // the max width of the output image, defaults to 1920px
    maxHeight: 750, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
  return resizedFiile;
};

export const base64toFileImage = async (dataUrl) => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], `${new Date().getTime()}`, { type: 'image/jpeg' });
};

export const uploadImage = async (image, outlet_id, destination) => {
  try {
    const serverEnv = await Mongo.Call('clientGetEnv', 'GET', null, 'BUNNY');
    const { REACT_APP_CDN_NAME, REACT_APP_CDN_PULL_ZONE, REACT_APP_CDN_KEY } =
      serverEnv;

    if (image?.url && outlet_id && destination) {
      const imageFile = await base64toFileImage(image.url);
      try {
        const uploadHost =
          import.meta.env.MODE.toLowerCase() !== 'production'
            ? 'uk.storage.bunnycdn.com'
            : 'sg.storage.bunnycdn.com';
        const compactFile = await resizeImageFn(imageFile);
        const getName = `${image.file.name.replace(/.jpeg|.jpg|.png/g, '')}`;
        const fileName = `${getName}_${moment().unix()}`;
        const path = `${outlet_id}/${folderName[destination]}/${fileName}.jpg`;

        const hitCloud = await fetch(
          `https://${uploadHost}/${REACT_APP_CDN_NAME}/${path}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/octet-stream',
              AccessKey: `${REACT_APP_CDN_KEY}`,
            },
            body: compactFile,
          },
        );
        const response = await hitCloud.json();
        return {
          url:
            response.HttpCode === 201
              ? `${REACT_APP_CDN_PULL_ZONE}/${path}`
              : '',
        };
      } catch (error) {
        sentSentryError(error, SentryLevel.Error);
        console.error('failed to upload image', error);
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    throw new Error('failed uploading image');
  }
};

export const compareDiffArray = (originData, newData) => {
  let result = originData
    .filter((x) => !newData.includes(x))
    .concat(newData.filter((x) => !originData.includes(x)));

  return result;
};

export const getDefaultBusiness = (
  props,
  getNormalizeOutlets,
  getNormalizeBusiness,
  getSelectedBusiness,
) => {
  const propsData = props.selectedData;
  const outlets = getNormalizeOutlets();
  const business = getNormalizeBusiness();

  if (propsData && Object.keys(propsData).length > 0) {
    const businessId = outlets.filter(
      (outlet) => outlet.value === propsData.outlet_id,
    )[0]?.business_id;
    const businessValue = business.find((data) => data.value === businessId);

    return businessValue || null;
  }

  if (!propsData?.id) {
    const businessValue = business.find(
      (data) => data.value === getSelectedBusiness(),
    );

    return businessValue || null;
  }
  return null;
};

export const getDefaultOutlet = (
  props,
  getNormalizeOutlets,
  getSelectedOutlet,
  getSelectedBusiness,
) => {
  const propsData = props.selectedData;

  if (propsData && Object.keys(propsData).length > 0) {
    // A. JIKA ADA PROPS DATA OUTLET YANG DISELECT
    return {
      label: propsData.outlet_name,
      value: propsData.outlet_id,
    };
  } else if (getNormalizeOutlets().length > 0) {
    // B. JIKA TIDAK ADA PROPS OUTLET TAPI DATA OUTLET SUDAH ADA
    if (!getSelectedOutlet()) {
      // 1. jika data nya ada tapi belum ada outlet yang di select
      if (getSelectedBusiness()) {
        // 1a. Jika outlet belum di select tapi sudah ada selected businessnya
        let sampleOutletCurrentBusiness = getNormalizeOutlets().find(
          (outlet) => outlet.business_id === getSelectedBusiness(),
        );

        return {
          label: sampleOutletCurrentBusiness.label,
          value: sampleOutletCurrentBusiness.value,
        };
      }

      // 1b. default value jika tidak masuk kondisi diatas
      return {
        label: getNormalizeOutlets()[0].label,
        value: getNormalizeOutlets()[0].value,
      };
    } else {
      // 2. jika sudah ada outlet yang di select
      const outlets = getNormalizeOutlets().find(
        (outlet) => outlet.value === getSelectedOutlet(),
      );

      return {
        label: outlets?.label,
        value: outlets?.value,
      };
    }
  }
  // C. default value jika tidak memenuhi semua kondisi diatas
  return null;
};

export const getDefaultActive = (props) => {
  return typeof props.selectedData.active === 'boolean'
    ? props.selectedData.active
    : true;
};

export const getMomentDateTime = (date, time) => {
  if (date) {
    if (time) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    }
    return moment(date).format('DD/MM/YYYY');
  }
  return date;
};

export const milisecToSec = (value) => {
  return (value / 1000).toFixed(2);
};

export const cloneData = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const regexOnlyNumberAndDot = (data) => {
  if (data) {
    return /[^\d|\.]+/g.test(data);
  }
  return /[^\d|\.]+/g;
};

export const calculateDiscount = (total, rate) => {
  return (total * rate) / 100;
};

export const _ISNOTPRODUCTION = () => {
  return (
    import.meta.env.MODE.toLowerCase() !== 'production' &&
    import.meta.env.MODE.toLowerCase() !== 'beta'
  );
};

export const getIdByState = (state, string) => {
  //get id for add or edit page by checking state.selectedData
  const getString = capitalizeFirstString(string);
  return state.selectedData && Object.keys(state.selectedData).length > 0
    ? `edit${getString}`
    : `add${getString}`;
};

export const sendMessageBOLogout = () => {
  senderPostMessageLogin(postMessageKeys.logoutPanelBO, null);
};

export const checkIsCredsExist = () => {
  if (!getLSCredential()) sendMessageBOLogout();
};

export const sendPathBO = (pathname) => {
  senderPostMessageLogin(pathname, null);
};

export const getPathToPrevPage = () => {
  const path = location.pathname;
  const getSpecificPath = path.substring(0, path.lastIndexOf('/'));

  sendPathBO(getSpecificPath);
  return getSpecificPath;
};

export const getMainPagePath = () => {
  let path = '/profile';

  if (getLSCredential()?.expired) {
    path = '/setting/subscription';
  } else if (getLSCredential()?.report_control) {
    path = '/report/sales';
  }
  return path;
};

export const brandName = (isNotFullText) => {
  return isNotFullText ? 'Grande' : 'Grande POS';
};
