import React, { useState, useContext, useEffect, useRef } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import { DrawerMenuData } from 'assets/file/DrawerMenuList.jsx';
import { logInfo } from 'utils/logging';
import { CookieName, setCookie } from 'utils/cookie';
import {
  setSessionBusiness,
  setSessionMenu,
  setSessionOutlet,
} from 'sessionstorage/setter';
import { getSessionBusiness, getSessionOutlet } from 'sessionstorage/getter';
import { sendPathBO } from 'utils/functions';
import PropTypes from 'prop-types';

/* ===========> BACA DULU SEBELUM EDIT <===========

TOLONG JANGAN MASUKKAN RANDOM VARIABLE KE SINI, ATAU KONSULTASIKAN KE MAINTAINER BO

===========> BACA DULU SEBELUM EDIT <=========== */

// fungsi ini digunakan untuk backup previous state
// kebutuhan untuk selectedOutlet dan selectedBusiness
const usePrev = (data) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = data;
  }, [data]);

  return ref.current;
};

export const DrawerContext = React.createContext([{}, () => {}]);

const DrawerProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const querySearch = location.search || '';

  const [state, setState] = useState({
    // toggledrawer small resolution
    openDrawer: false,
    // hide or show drawer for other admin panel
    showDrawer: false,
    selectedMenu: '',
    selectedSubMenu: '',
    selectedOutlet: getSessionOutlet() || '',
    selectedBusiness: getSessionBusiness() || '',
    withFilter: true,
    outletFilter: true,
    bisnisOutletFilter: true,
    useAllOutletFilter: true,
    filterButton: true,
    currentUser: {},
    title: '',

    // context right Filter
    rightDrawerShow: false,
    disableFilterComfirm: true,
    checkFilterSelected: [],
  });

  const setDrawerState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getDrawerState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const getPreviousState = usePrev(getDrawerState());

  const toggleDrawer = (event, value) => {
    setDrawerState({
      openDrawer: typeof value === 'boolean' ? value : !state.openDrawer,
    });
  };

  const setSelectedMenu = (menuKey) => {
    setDrawerState({
      selectedMenu: menuKey,
      selectedSubMenu: '',
    });
  };

  const getSelectedMenu = () => {
    return getDrawerState().selectedMenu;
  };

  const setSelectedsubMenu = (subMenuKey, menuKey) => {
    if (menuKey) {
      setDrawerState({
        selectedSubMenu: subMenuKey,
        selectedMenu: menuKey,
      });
    } else {
      setDrawerState({
        selectedSubMenu: subMenuKey,
      });
    }
  };

  const getSelectedsubMenu = () => {
    return getDrawerState().selectedSubMenu;
  };

  const isMenuSelected = (menuKey) => {
    return getSelectedMenu() === menuKey;
  };

  const isSubMenuSelected = (subMenuKey) => {
    return getSelectedsubMenu() === subMenuKey;
  };

  const getTitle = () => {
    return getDrawerState().title;
  };

  const setTitle = (props) => {
    const {
      newTitle,
      menuKey,
      subMenuKey,
      filterParam,
      outletFilter,
      useAllOutletFilter,
      filterButton,
      bisnisOutletFilter,
    } = props.data;

    setDrawerState({
      title: newTitle,
      selectedMenu: menuKey || getSelectedMenu(),
      selectedSubMenu: subMenuKey || getSelectedsubMenu(),
      withFilter: typeof filterParam === 'boolean' ? filterParam : true,
      outletFilter: typeof outletFilter === 'boolean' ? outletFilter : true,
      useAllOutletFilter:
        typeof useAllOutletFilter === 'boolean' ? useAllOutletFilter : true,
      filterButton: typeof filterButton === 'boolean' ? filterButton : true,
      bisnisOutletFilter:
        typeof bisnisOutletFilter === 'boolean' ? bisnisOutletFilter : true,
    });
  };

  const isRightDrawerShow = () => {
    return getDrawerState().rightDrawerShow;
  };

  const toggleRightFilter = () => {
    setDrawerState({
      rightDrawerShow: !isRightDrawerShow(),
    });
  };

  const getSelectedOutlet = () => {
    return getDrawerState().selectedOutlet === 'all'
      ? ''
      : getDrawerState().selectedOutlet;
  };

  const setSelectedOutlet = (value) => {
    let localValue = value === 'all' ? '' : value;
    setSessionOutlet(localValue);
    setDrawerState({ selectedOutlet: localValue });
  };

  const getSelectedBusiness = () => {
    return getDrawerState().selectedBusiness;
  };

  const setSelectedBusiness = (value) => {
    setSessionBusiness(value);
    setSessionOutlet('');
    setDrawerState({ selectedBusiness: value, selectedOutlet: 'all' });
  };

  useEffect(() => {
    const currentMenu = `/${getSelectedMenu()}${
      getSelectedsubMenu() ? `/${getSelectedsubMenu()}` : ''
    }`;

    logInfo(`Drawer active path: ${currentMenu}`);
    setSessionMenu(currentMenu);

    switch (getSelectedMenu()) {
      // case DrawerMenuData.dashboard.key:
      //   navigate(`/${DrawerMenuData.dashboard.key}`);
      //   break;
      case DrawerMenuData.profile.key:
      case DrawerMenuData.product.key:
      case DrawerMenuData.manageOutlet.key:
      case DrawerMenuData.employee.key:
      case DrawerMenuData.inventory.key:
      case DrawerMenuData.utility.key:
      case DrawerMenuData.member.key:
      case DrawerMenuData.invoice.key:
      case DrawerMenuData.setting.key:
      case DrawerMenuData.table.key:
      case DrawerMenuData.report.key:
      case DrawerMenuData.promo.key:
        switch (getSelectedsubMenu()) {
          case DrawerMenuData.product.subMenu.group.key:
          case DrawerMenuData.product.subMenu.department.key:
          case DrawerMenuData.product.subMenu.menuPage.key:
          case DrawerMenuData.product.subMenu.menuVariant.key:
          case DrawerMenuData.product.subMenu.modifier.key:
          case DrawerMenuData.product.subMenu.package.key:
          case DrawerMenuData.product.subMenu.productList.key:
          case DrawerMenuData.manageOutlet.subMenu.outletList.key:
          case DrawerMenuData.manageOutlet.subMenu.device.key:
          case DrawerMenuData.manageOutlet.subMenu.configuration.key:
          case DrawerMenuData.employee.subMenu.employeeList.key:
          case DrawerMenuData.employee.subMenu.role.key:
          case DrawerMenuData.inventory.subMenu.stockSummary.key:
          case DrawerMenuData.inventory.subMenu.stockAdjustment.key:
          case DrawerMenuData.inventory.subMenu.stockAudit.key:
          case DrawerMenuData.utility.subMenu.typeSales.key:
          case DrawerMenuData.utility.subMenu.refundMemo.key:
          case DrawerMenuData.utility.subMenu.voidMemo.key:
          case DrawerMenuData.utility.subMenu.paymentMedia.key:
          case DrawerMenuData.utility.subMenu.taxes.key:
          case DrawerMenuData.utility.subMenu.discount.key:
          case DrawerMenuData.utility.subMenu.commission.key:
          case DrawerMenuData.utility.subMenu.billDesign.key:
          case DrawerMenuData.setting.subMenu.deviceLicense.key:
          case DrawerMenuData.table.subMenu.tableLayout.key:
          case DrawerMenuData.report.subMenu.sales.key:
            sendPathBO(currentMenu);
            navigate(currentMenu, {
              state: {
                query: querySearch,
              },
            });
            break;

          // untuk url ke subscription, ada pengecualian
          // ketika dibuka oleh pos yang mengirimkan expired,
          // forward langsung ke pathname nya
          // jika bukan dari pos, maka sesuai prosedur BO
          case DrawerMenuData.setting.subMenu.billingHistory.key:
          case DrawerMenuData.setting.subMenu.subscription.key:
            if (location.state?.expired) {
              sendPathBO(`${location.pathname}`);
              navigate(`${location.pathname}`, {
                state: {
                  expired: location.state?.expired,
                },
              });
            } else {
              sendPathBO(currentMenu);
              navigate(currentMenu, {
                state: {
                  query: querySearch,
                },
              });
            }
            break;

          default:
            sendPathBO(`/${getSelectedMenu()}`);
            navigate(`/${getSelectedMenu()}`);
            break;
        }
        break;

      case DrawerMenuData.emoney.key:
        if (location.pathname.includes('activate')) {
          sendPathBO(location.pathname);
          navigate(location.pathname);
        } else {
          sendPathBO(`/${getSelectedMenu()}`);
          navigate(`/${getSelectedMenu()}`);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [state.selectedMenu, state.selectedSubMenu]);

  useEffect(() => {
    // cooking diperlukan untuk sentry fallback,
    // karena sentry init ada di APP js, dan selectedBusiness/Outlet ada di DrawerContext
    // maka App js tidak bisa mengambil data selectedBusiness/Outlet diperlukan sesuatu yang general
    // untuk saat ini menggunakan cookie

    setCookie(
      `${CookieName.SELECTED_BUSINESS}=${state.selectedBusiness || ''};`,
    );
    setCookie(`${CookieName.SELECTED_OUTLET}=${state.selectedOutlet || ''};`);
  }, [state.selectedBusiness, state.selectedOutlet]);

  return (
    <DrawerContext.Provider
      value={{
        getDrawerState,
        setDrawerState,
        getPreviousState,

        toggleDrawer,
        toggleRightFilter,

        setSelectedMenu,
        getSelectedMenu,
        setSelectedsubMenu,
        getSelectedsubMenu,

        isMenuSelected,
        isSubMenuSelected,

        setTitle,
        getTitle,

        getSelectedOutlet,
        setSelectedOutlet,
        getSelectedBusiness,
        setSelectedBusiness,
      }}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerContext = () => {
  const value = useContext(DrawerContext);
  if (value == null) {
    throw new Error('useDrawerContext() called outside of a PosProvider?');
  }
  return value;
};

DrawerProvider.propTypes = {
  children: PropTypes.any,
  data: PropTypes.object,
};

export default DrawerProvider;
