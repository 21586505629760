import { useEffect, useState } from 'react';
import { useContentContext } from 'context/ContentContext';
import { setLSDevlog } from 'localstorage/setter';
import { getLSDevlog } from 'localstorage/getter';
import ReOvalButton from './ReOvalButton';

const reportPath = import.meta.env.VITE_REPORT_UI;

const DevInfo = () => {
  const { sendCustomPM } = useContentContext();
  const [localState, setLocalState] = useState({
    disableBOLog: false,
    disableReportLog: false,
  });

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const updateLocalState = (newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getDevLog = () => {
    const devlog = getLSDevlog();
    return devlog;
  };

  const handleChangeLogBO = () => {
    const oldData = getDevLog();
    const newData = {
      ...oldData,
      disable_bo_log: !getLocalState().disableBOLog,
    };
    setLSDevlog(newData);
    updateLocalState({ disableBOLog: !getLocalState().disableBOLog });
  };

  const handleChangeLogReport = () => {
    const oldData = getDevLog();
    const newData = {
      ...oldData,
      disable_report_log: !getLocalState().disableReportLog,
    };
    setLSDevlog(newData);
    sendCustomPM({ disable_report_log: !getLocalState().disableReportLog });
    updateLocalState({ disableReportLog: !getLocalState().disableReportLog });
  };

  useEffect(() => {
    updateLocalState({
      disableBOLog: getDevLog()?.disable_bo_log || false,
      disableReportLog: getDevLog()?.disable_report_log || false,
    });
  }, []);

  return (
    <table border="0">
      <tr>
        <td>DISABLE BO LOG :</td>
        <td>{getLocalState().disableBOLog.toString()}</td>
        <td>
          <input
            type="button"
            value={(!getLocalState().disableBOLog).toString()}
            onClick={() => {
              handleChangeLogBO();
            }}
          />
        </td>
      </tr>
      <tr>
        <td>DISABLE REPORT LOG :</td>
        <td>{getLocalState().disableReportLog.toString()}</td>
        <td>
          <input
            type="button"
            value={(!getLocalState().disableReportLog).toString()}
            onClick={() => {
              handleChangeLogReport();
            }}
          />
        </td>
      </tr>
      <tr>
        <td>
          <ReOvalButton
            color="secondary"
            label="BO-INFO"
            onClick={() => {
              window.open('/dev/info', '_blank');
            }}
          />
        </td>
        <td>
          <ReOvalButton
            color="secondary"
            label="REPORT-INFO"
            onClick={() => {
              window.open(`${reportPath}/info`, '_blank');
            }}
          />
        </td>
      </tr>
    </table>
  );
};

export default DevInfo;
