import { getLSCredential } from 'localstorage/getter';
import { SentryLevel, sentSentryError } from 'utils/sentry';
export const realmWebhook = {
  businessCategory: 'clientBusinessCategory',
  countries: 'clientGetCountry',
  clientGetProvince: 'clientGetProvince',
  clientGetCity: 'clientGetCity',
  clientGetRegion: 'clientGetRegion',
};

export const callWebhook = async (endpoint, body, method, headers) => {
  const localBody = body || {};
  const localMethod = method || 'POST';
  try {
    if (getLSCredential()?.app) {
      const getResponse = await fetch(
        `${import.meta.env.VITE_WEBHOOK_URL.replace(
          '[APPID]',
          getLSCredential().app,
        )}/${endpoint}`,
        {
          method: localMethod,
          headers: headers || {
            'Content-Type': 'application/json',
          },
          body:
            localMethod === 'POST' ||
            localMethod === 'PUT' ||
            localMethod === 'DELETE'
              ? JSON.stringify(localBody)
              : null,
        },
      );
      const res = await getResponse.json();
      return res;
    }
  } catch (e) {
    // handle network error
    sentSentryError(e, SentryLevel.Error);
    return { status: false, message: e.toString() };
  }
};
