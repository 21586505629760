const loginPath = import.meta.env.VITE_LOGIN_UI;
const reportPath = import.meta.env.VITE_REPORT_UI;

export const postMessageKeys = {
  sendPMCredential: 'bo_login',
  logoutPanelBO: 'logout',
  selectType: 'SelectType',
  changeFilterDate: 'ChangeFilterDate',
  openDetailSummary: 'OpenDetailSummary',
  openDetailGroup: 'OpenDetailGroup',
  loginReport: 'LoginReport',
  logoutReport: 'LogoutReport',
  resize: 'resize',
  custom: 'custom',
  lang: 'lang',
};

const lintenerPostMessage = (path, callback) => {
  window.addEventListener('message', (event) => {
    if (event.origin !== path || event?.data?.type?.includes('webpack')) {
      // jika origin tidak sesuai atau unexpected PM webpack
      // return null ke listener
      callback(null);
    } else if (JSON.parse(event?.data)?.data?.iframe_received) {
      // jika iframe_received (untuk console.log development)
      // console.log iframe received, dan return null ke listener
      // gak bisa di satukan dengan diatas karena beda logic
      console.log(JSON.parse(event?.data), '<< berhasil mengirim ke iframe');
      callback(null);
    } else {
      // listen PM normal, jika data JSON, maka parse, jika string, masuk catch
      let result = null;
      try {
        result = JSON.parse(event.data);
      } catch (_) {
        result = event.data;
      }
      callback(result);
    }
  });
};

// BO LOGIN
export const listenerPostMessageLogin = (callback) => {
  lintenerPostMessage(loginPath, callback);
};

export const senderPostMessageLogin = (key, data) => {
  const payload = data ? JSON.stringify({ key, data }) : key;
  window.parent.postMessage(payload, loginPath);
};

// BO REPORT
export const listenerPostMessageReport = (callback) => {
  lintenerPostMessage(reportPath, callback);
};

export const senderPostMessageReport = (targetRef, key, data, isOnLoad) => {
  if (targetRef) {
    const payload = {
      key,
      data,
    };

    // isOnLoad digunakan untuk postMessage ke iframe saat useEffect
    // karena ketika postmessage sewaktu useEffect, iframe belum ready
    if (isOnLoad) {
      targetRef.addEventListener('load', () => {
        targetRef.contentWindow.postMessage(
          JSON.stringify(payload),
          reportPath,
        );
      });
    } else {
      targetRef.contentWindow.postMessage(JSON.stringify(payload), reportPath);
    }
  }
};
