import { useMediaQuery } from '@material-ui/core';
import { DrawerMenuData } from 'assets/file/DrawerMenuList.jsx';
import { getLSDevMenu } from 'localstorage/getter';
import { setLSDevMenu } from 'localstorage/setter';
import { useState } from 'react';
import { sendMessageBOLogout } from 'utils/functions';
import { logoutBackOffice } from 'utils/helper';

const getDisabledMenu = (menu) => {
  return DrawerMenuData[menu].disabled === true;
};

const getDisabledSubMenu = (sub, subMenu) => {
  return subMenu[sub].disabled === true;
};

const DevTools = () => {
  const mobileScreen = useMediaQuery('(max-width:600px)');
  const [vis, setVis] = useState(false);

  return (
    <>
      <button
        style={{
          zIndex: 100000,
          position: 'absolute',
          left: mobileScreen ? 4 : 12,
          bottom: mobileScreen ? 4 : 24,
        }}
        onClick={() => {
          setVis(!vis);
        }}>
        DEV TOOLS
      </button>
      {vis && (
        <div
          style={{
            position: 'absolute',
            left: 12,
            top: 80,
            zIndex: 100000,
            backgroundColor: 'pink',
            width: 300,
            height: 500,
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 6,
            }}>
            <h2>DEV TOOLS</h2>
            <button
              style={{ height: 30, width: '100%', cursor: 'pointer' }}
              onClick={() => {
                logoutBackOffice();
                sendMessageBOLogout();
              }}>
              FORCE CLEAR ALL & LOGOUT
            </button>
            {Object.keys(DrawerMenuData).map((menu) => {
              if (getDisabledMenu(menu)) {
                return (
                  <button
                    key={menu}
                    style={{ height: 30, width: '100%', cursor: 'pointer' }}
                    onClick={() => {
                      const oldMenu = getLSDevMenu();
                      if (
                        Array.isArray(oldMenu) &&
                        !oldMenu.includes(DrawerMenuData[menu].key)
                      ) {
                        setLSDevMenu([...oldMenu, DrawerMenuData[menu].key]);
                      }
                    }}>
                    ENABLE : {DrawerMenuData[menu].key}
                  </button>
                );
              }
              return <></>;
            })}
            {Object.keys(DrawerMenuData).map((menu) => {
              const subMenu = DrawerMenuData[menu].subMenu;
              if (Object.keys(subMenu).length > 0) {
                return Object.keys(subMenu).map((sub) => {
                  if (getDisabledSubMenu(sub, subMenu)) {
                    return (
                      <button
                        key={sub}
                        style={{
                          height: 30,
                          width: '100%',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          const oldMenu = getLSDevMenu();
                          if (
                            Array.isArray(oldMenu) &&
                            !oldMenu.includes(subMenu[sub].key)
                          ) {
                            setLSDevMenu([...oldMenu, subMenu[sub].key]);
                          }
                        }}>
                        ENABLE : {subMenu[sub].key}
                      </button>
                    );
                  }
                  return <></>;
                });
              }

              return <></>;
            })}
            <button
              style={{
                height: 30,
                width: '100%',
                cursor: 'pointer',
                position: 'absolute',
                bottom: 12,
              }}
              onClick={() => {
                window.location.reload(false);
              }}>
              RELOAD PAGE
            </button>
          </div>
        </div>
      )}
    </>
  );
};

DevTools.displayName = 'DevTools';

DevTools.propTypes = {};

export default DevTools;
